/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RiepElemControllo { 
    descrBase?: string;
    idSerCon?: number;
    descrSerCon?: string;
    descrCli?: string;
    descrSer?: string;
    codiceBase?: string;
    idCli?: number;
    idSer?: number;
    sccbId?: number;
    codiceCli?: string;
    codiceSer?: string;
    idCliCon?: number;
    idBaseCli?: number;
}


