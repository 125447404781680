/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SchedaDetRilevazioneAllOf { 
    descrIndicatore?: string;
    contestato?: boolean;
    preventivo?: number;
    valorePuntuale?: number;
    peso?: number;
    codiceEleControllo?: string;
    nomeAllegato?: string;
    noteRil?: string;
    idSchedaTesRil?: number;
    prog?: number;
    nonValutabile?: boolean;
    soglia?: string;
    descrEleControllo?: string;
    descr?: string;
    punteggio?: number;
    codiceIndicatore?: string;
    puntPond?: number;
    id?: number;
    idIndicatore?: number;
    idEleControllo?: number;
}


