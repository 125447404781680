/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FunzioniSecondarie = 'LOOKUP' | 'SELECT' | 'INSERT' | 'UPDATE' | 'DELETE';

export const FunzioniSecondarie = {
    Lookup: 'LOOKUP' as FunzioniSecondarie,
    Select: 'SELECT' as FunzioniSecondarie,
    Insert: 'INSERT' as FunzioniSecondarie,
    Update: 'UPDATE' as FunzioniSecondarie,
    Delete: 'DELETE' as FunzioniSecondarie
};

