/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MenuItem { 
    ordine: number;
    idFunzionePrincipale: number;
    codiceFunzionePrincipale?: string;
    tipo?: string;
    idVocePadre: number;
    sottovoci?: Array<MenuItem>;
    url?: string;
    descrizione?: string;
    abilitato: boolean;
    id: number;
    icona?: string;
    abilitazioni?: object;
    livello: number;
}


