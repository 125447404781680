/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatNCRiepilogoNonConformita { 
    nonConformitaDeltaChiusura?: number;
    lottoId?: number;
    lottodescr?: string;
    nonConformitaPercentImpattoMinore?: number;
    nonConformitaNumeroTotale?: number;
    periodoId?: number;
    nonConformitaNumeroAperte?: number;
    servizioId?: number;
    nonConformitaPercentImpattoMaggiore?: number;
    nonConformitaPercentChiuse?: number;
    clienteId?: number;
    periododescr?: string;
    clienteRagSoc?: string;
    serviziodescr?: string;
    nonConformitaNumeroChiuse?: number;
    nonConformitaPercentCritiche?: number;
}


