/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SegnalazioneStats { 
    aperCodStato?: string;
    aperDesc?: string;
    aperDelta?: number;
    pcarDelta?: number;
    contratto?: string;
    descGruppoUO?: string;
    codiceSegnalazione?: number;
    oggettoSegnalazione?: string;
    aperUtente?: string;
    stato?: string;
    pcarData?: string;
    cforDesc?: string;
    servizio?: string;
    flagUrgenza?: string;
    codUnitaOperativa?: string;
    id?: number;
    id_serCliCon?: number;
    ccliDelta?: number;
    ccliDesc?: string;
    ccliUtente?: string;
    dataChiusura?: string;
    ccliCodStato?: string;
    id_unitaOperativa?: number;
    pcarCodStato?: string;
    dataApertura?: string;
    aperData?: string;
    cforDelta?: number;
    pcarDesc?: string;
    codiceUnitaOperativa?: string;
    cforUtente?: string;
    descrizioneUnitaOperativa?: string;
    cforData?: string;
    descrizione?: string;
    pcarUtente?: string;
    codGruppoUO?: string;
    descUnitaOperativa?: string;
    ccliData?: string;
    cforCodStato?: string;
}


