/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatNCDettaglioNonConformita { 
    nonConformitaDeltaChiusura?: number;
    nonConformitaChiusa?: boolean;
    lottoId?: number;
    nonConformitaDataChiusuraEff?: string;
    lottodescr?: string;
    baseDescr?: string;
    baseId?: number;
    idSchedaTesRil?: number;
    periodoId?: number;
    nonConformitaRifAC?: string;
    servizioId?: number;
    clienteId?: number;
    nonConformitaTipo?: string;
    periododescr?: string;
    clienteRagSoc?: string;
    nonConformitaId?: number;
    serviziodescr?: string;
    nonConformitaDataChiusuraPrev?: string;
    nonConformitaResponsabile?: string;
}


