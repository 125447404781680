/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AllegatoPerUtente {
    timeStamp?: string;
    dataIns?: string;
    ipInsMod?: string;
    userNameUteIns?: string;
    userNameUteMod?: string;
    idUteIns?: number;
    ipIns?: string;
    dataMod?: string;
    idUteMod?: number;
    numeroContratto?: string;
    note?: string;
    fileName?: string;
    progressivo?: number;
    idSerCon?: number;
    idCon?: number;
    idCli?: number;
    idSer?: number;
    fornitoreRagSoc?: string;
    serContratto?: string;
    clienteRagSoc?: string;
    idFor?: number;
    servizio?: string;
    id?: number;
    idCliCon?: number;
}


