import {Injectable} from '@angular/core';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ErrorCode, Result} from '../../api';
import {TranslatePipe} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(private dialogService: DialogService, private translate: TranslatePipe) {
  }

  public showErrorDialog(jsonKoResult: string): void {
    const result: Result = JSON.parse(jsonKoResult);

    let titleKey = '';
    let bodyKey = '';
    let positiveButtonKey = '';
    // let negativeButtonKey = '';

    if (result.code === ErrorCode.Concurrency) {
      titleKey = 'COMMON.CONFIRM_CONCURRENCY_TITLE';
      bodyKey = 'COMMON.CONFIRM_CONCURRENCY_BODY';
      positiveButtonKey = 'COMMON.CONFIRM_CONCURRENCY_OK';
    } else if (result.code === ErrorCode.UniqueKey) {
      titleKey = 'COMMON.CONFIRM_UNIQUE_KEY_TITLE';
      bodyKey = 'COMMON.CONFIRM_UNIQUE_KEY_BODY';
      positiveButtonKey = 'COMMON.CONFIRM_UNIQUE_KEY_OK';
    } else if (result.code === ErrorCode.ForeignKey) {
      titleKey = 'ERRORS.FOREIGN_KEY_DEFAULT_TITLE';
      const defaultKey = 'ERRORS.FOREIGN_KEY_DEFAULT_BODY';

      if (result.dbErrorDetails && result.opType) {
        try {
          // Cerco di restituire un errore più parlante
          const keyToTest = 'ERRORS.FOREIGN_KEY_' + (result.dbErrorDetails[0] as any).table + '_DEL_body';
          bodyKey = this.translate.transform(keyToTest) !== keyToTest ? keyToTest : defaultKey;
        } catch (e) {
          bodyKey = defaultKey;
        }
      }
      positiveButtonKey = 'COMMON.CONFIRM_FOREIGN_KEY_OK';
    } else {
      // TODO
    }

    this.dialogService.open({
      title: this.translate.transform(titleKey),
      content: this.translate.transform(bodyKey),
      actions: [
        // {text: 'No'},
        {text: this.translate.transform(positiveButtonKey, ''), primary: true}
      ],
    });
  }
}
