/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SchedaDet {
    timeStamp?: string;
    dataIns?: string;
    ipInsMod?: string;
    userNameUteIns?: string;
    userNameUteMod?: string;
    idUteIns?: number;
    ipIns?: string;
    dataMod?: string;
    idUteMod?: number;
    peso?: number;
    schedaUtente?: boolean;
    codiceEleControllo?: string;
    idSchedaTes?: number;
    descrizioneIndicatore?: string;
    prog?: number;
    soglia?: string;
    descr?: string;
    descrizioneEleControllo?: string;
    codiceIndicatore?: string;
    id?: number;
    idIndicatore?: number;
    idEleControllo?: number;
}


