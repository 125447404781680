/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatNCRiepilogoAzioneCorrettiva { 
    lottoId?: number;
    azioneCorrettivaDeltaChiusura?: number;
    lottodescr?: string;
    azioneCorrettivaNumeroAperte?: number;
    azioneCorrettivaPercentProdotto?: number;
    periodoId?: number;
    azioneCorrettivaPercentServizio?: number;
    servizioId?: number;
    clienteId?: number;
    periododescr?: string;
    clienteRagSoc?: string;
    azioneCorrettivaNumeroTotale?: number;
    serviziodescr?: string;
    azioneCorrettivaPercentProcesso?: number;
    azioneCorrettivaNumeroChiuse?: number;
    azioneCorrettivaPercentChiuse?: number;
}


