/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ErrorCode = 'GENERIC' | 'CONCURRENCY' | 'UNIQUE_KEY' | 'FOREIGN_KEY' | 'LOGIN' | 'ANAUTHORIZED' | 'MISSING_PARAMETER';

export const ErrorCode = {
    Generic: 'GENERIC' as ErrorCode,
    Concurrency: 'CONCURRENCY' as ErrorCode,
    UniqueKey: 'UNIQUE_KEY' as ErrorCode,
    ForeignKey: 'FOREIGN_KEY' as ErrorCode,
    Login: 'LOGIN' as ErrorCode,
    Anauthorized: 'ANAUTHORIZED' as ErrorCode,
    MissingParameter: 'MISSING_PARAMETER' as ErrorCode
};

