/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ServizioClienteContrattoBaseLookup { 
    idAssLottiPunti?: number;
    idPuntoStocc?: number;
    idLottoContrBase?: number;
    id?: number;
}


