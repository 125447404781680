/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

import {
  GetListaStatControlliLottoPerControlloreRequest,
  GetListaStatControlliLottoPerControlloreResponse,
  GetListaStatDettaglioSchedaConEsposizioneRequest,
  GetListaStatDettaglioSchedaConEsposizioneResponse,
  GetListaStatDettaglioSchedeRequest,
  GetListaStatDettaglioSchedeResponse,
  GetListaStatIndServGlobaleTyBasePeriodoRequest,
  GetListaStatIndServGlobaleTyBasePeriodoResponse,
  GetListaStatIndServGlobaleTyServizioPeriodoRequest,
  GetListaStatIndServGlobaleTyServizioPeriodoResponse,
  GetListaStatIndServLottoPeriodoRequest,
  GetListaStatIndServLottoPeriodoResponse,
  GetListaStatIndServLottoPuntoControlloRequest,
  GetListaStatIndServLottoPuntoControlloResponse,
  GetListaStatisticheGlobaliRequest,
  GetListaStatisticheGlobaliResponse,
  GetListaStatNonConfIndicatoriRequest,
  GetListaStatNonConfIndicatoriResponse,
  GetListaStatNonConfLottoPuntoControlloRequest,
  GetListaStatNonConfLottoPuntoControlloResponse
} from '../model/models';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class StatisticheControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param getListaStatControlliLottoPerControlloreRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatControlliLottoPerControlloreUsingPOST(getListaStatControlliLottoPerControlloreRequest: GetListaStatControlliLottoPerControlloreRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatControlliLottoPerControlloreResponse>;
    public getListaStatControlliLottoPerControlloreUsingPOST(getListaStatControlliLottoPerControlloreRequest: GetListaStatControlliLottoPerControlloreRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatControlliLottoPerControlloreResponse>>;
    public getListaStatControlliLottoPerControlloreUsingPOST(getListaStatControlliLottoPerControlloreRequest: GetListaStatControlliLottoPerControlloreRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatControlliLottoPerControlloreResponse>>;
    public getListaStatControlliLottoPerControlloreUsingPOST(getListaStatControlliLottoPerControlloreRequest: GetListaStatControlliLottoPerControlloreRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatControlliLottoPerControlloreRequest === null || getListaStatControlliLottoPerControlloreRequest === undefined) {
            throw new Error('Required parameter getListaStatControlliLottoPerControlloreRequest was null or undefined when calling getListaStatControlliLottoPerControlloreUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatControlliLottoPerControlloreResponse>(`${this.configuration.basePath}/api/getListaStatControlliLottoPerControllore`,
            getListaStatControlliLottoPerControlloreRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatDettaglioSchedaConEsposizioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatDettaglioSchedaConEsposizioneUsingPOST(getListaStatDettaglioSchedaConEsposizioneRequest: GetListaStatDettaglioSchedaConEsposizioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatDettaglioSchedaConEsposizioneResponse>;
    public getListaStatDettaglioSchedaConEsposizioneUsingPOST(getListaStatDettaglioSchedaConEsposizioneRequest: GetListaStatDettaglioSchedaConEsposizioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatDettaglioSchedaConEsposizioneResponse>>;
    public getListaStatDettaglioSchedaConEsposizioneUsingPOST(getListaStatDettaglioSchedaConEsposizioneRequest: GetListaStatDettaglioSchedaConEsposizioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatDettaglioSchedaConEsposizioneResponse>>;
    public getListaStatDettaglioSchedaConEsposizioneUsingPOST(getListaStatDettaglioSchedaConEsposizioneRequest: GetListaStatDettaglioSchedaConEsposizioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatDettaglioSchedaConEsposizioneRequest === null || getListaStatDettaglioSchedaConEsposizioneRequest === undefined) {
            throw new Error('Required parameter getListaStatDettaglioSchedaConEsposizioneRequest was null or undefined when calling getListaStatDettaglioSchedaConEsposizioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatDettaglioSchedaConEsposizioneResponse>(`${this.configuration.basePath}/api/getListaStatDettaglioSchedeEsposizione`,
            getListaStatDettaglioSchedaConEsposizioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatDettaglioSchedeRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatDettaglioSchedeUsingPOST(getListaStatDettaglioSchedeRequest: GetListaStatDettaglioSchedeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatDettaglioSchedeResponse>;
    public getListaStatDettaglioSchedeUsingPOST(getListaStatDettaglioSchedeRequest: GetListaStatDettaglioSchedeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatDettaglioSchedeResponse>>;
    public getListaStatDettaglioSchedeUsingPOST(getListaStatDettaglioSchedeRequest: GetListaStatDettaglioSchedeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatDettaglioSchedeResponse>>;
    public getListaStatDettaglioSchedeUsingPOST(getListaStatDettaglioSchedeRequest: GetListaStatDettaglioSchedeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatDettaglioSchedeRequest === null || getListaStatDettaglioSchedeRequest === undefined) {
            throw new Error('Required parameter getListaStatDettaglioSchedeRequest was null or undefined when calling getListaStatDettaglioSchedeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatDettaglioSchedeResponse>(`${this.configuration.basePath}/api/getListaStatDettaglioSchede`,
            getListaStatDettaglioSchedeRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatIndServGlobaleTyBasePeriodoRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatIndServGlobaleTyBasePeriodoUsingPOST(getListaStatIndServGlobaleTyBasePeriodoRequest: GetListaStatIndServGlobaleTyBasePeriodoRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatIndServGlobaleTyBasePeriodoResponse>;
    public getListaStatIndServGlobaleTyBasePeriodoUsingPOST(getListaStatIndServGlobaleTyBasePeriodoRequest: GetListaStatIndServGlobaleTyBasePeriodoRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatIndServGlobaleTyBasePeriodoResponse>>;
    public getListaStatIndServGlobaleTyBasePeriodoUsingPOST(getListaStatIndServGlobaleTyBasePeriodoRequest: GetListaStatIndServGlobaleTyBasePeriodoRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatIndServGlobaleTyBasePeriodoResponse>>;
    public getListaStatIndServGlobaleTyBasePeriodoUsingPOST(getListaStatIndServGlobaleTyBasePeriodoRequest: GetListaStatIndServGlobaleTyBasePeriodoRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatIndServGlobaleTyBasePeriodoRequest === null || getListaStatIndServGlobaleTyBasePeriodoRequest === undefined) {
            throw new Error('Required parameter getListaStatIndServGlobaleTyBasePeriodoRequest was null or undefined when calling getListaStatIndServGlobaleTyBasePeriodoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatIndServGlobaleTyBasePeriodoResponse>(`${this.configuration.basePath}/api/getListaStatIndServGlobaleTyBasePeriodo`,
            getListaStatIndServGlobaleTyBasePeriodoRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatIndServGlobaleTyServizioPeriodoRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatIndServGlobaleTyServizioPeriodoUsingPOST(getListaStatIndServGlobaleTyServizioPeriodoRequest: GetListaStatIndServGlobaleTyServizioPeriodoRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatIndServGlobaleTyServizioPeriodoResponse>;
    public getListaStatIndServGlobaleTyServizioPeriodoUsingPOST(getListaStatIndServGlobaleTyServizioPeriodoRequest: GetListaStatIndServGlobaleTyServizioPeriodoRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatIndServGlobaleTyServizioPeriodoResponse>>;
    public getListaStatIndServGlobaleTyServizioPeriodoUsingPOST(getListaStatIndServGlobaleTyServizioPeriodoRequest: GetListaStatIndServGlobaleTyServizioPeriodoRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatIndServGlobaleTyServizioPeriodoResponse>>;
    public getListaStatIndServGlobaleTyServizioPeriodoUsingPOST(getListaStatIndServGlobaleTyServizioPeriodoRequest: GetListaStatIndServGlobaleTyServizioPeriodoRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatIndServGlobaleTyServizioPeriodoRequest === null || getListaStatIndServGlobaleTyServizioPeriodoRequest === undefined) {
            throw new Error('Required parameter getListaStatIndServGlobaleTyServizioPeriodoRequest was null or undefined when calling getListaStatIndServGlobaleTyServizioPeriodoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatIndServGlobaleTyServizioPeriodoResponse>(`${this.configuration.basePath}/api/getListaStatIndServGlobaleTyServizioPeriodo`,
            getListaStatIndServGlobaleTyServizioPeriodoRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatIndServLottoPeriodoRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatIndServLottoPeriodoUsingPOST(getListaStatIndServLottoPeriodoRequest: GetListaStatIndServLottoPeriodoRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatIndServLottoPeriodoResponse>;
    public getListaStatIndServLottoPeriodoUsingPOST(getListaStatIndServLottoPeriodoRequest: GetListaStatIndServLottoPeriodoRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatIndServLottoPeriodoResponse>>;
    public getListaStatIndServLottoPeriodoUsingPOST(getListaStatIndServLottoPeriodoRequest: GetListaStatIndServLottoPeriodoRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatIndServLottoPeriodoResponse>>;
    public getListaStatIndServLottoPeriodoUsingPOST(getListaStatIndServLottoPeriodoRequest: GetListaStatIndServLottoPeriodoRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatIndServLottoPeriodoRequest === null || getListaStatIndServLottoPeriodoRequest === undefined) {
            throw new Error('Required parameter getListaStatIndServLottoPeriodoRequest was null or undefined when calling getListaStatIndServLottoPeriodoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatIndServLottoPeriodoResponse>(`${this.configuration.basePath}/api/getListaStatIndServLottoPeriodo`,
            getListaStatIndServLottoPeriodoRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatIndServLottoPuntoControlloRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatIndServLottoPuntoControlloUsingPOST(getListaStatIndServLottoPuntoControlloRequest: GetListaStatIndServLottoPuntoControlloRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatIndServLottoPuntoControlloResponse>;
    public getListaStatIndServLottoPuntoControlloUsingPOST(getListaStatIndServLottoPuntoControlloRequest: GetListaStatIndServLottoPuntoControlloRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatIndServLottoPuntoControlloResponse>>;
    public getListaStatIndServLottoPuntoControlloUsingPOST(getListaStatIndServLottoPuntoControlloRequest: GetListaStatIndServLottoPuntoControlloRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatIndServLottoPuntoControlloResponse>>;
    public getListaStatIndServLottoPuntoControlloUsingPOST(getListaStatIndServLottoPuntoControlloRequest: GetListaStatIndServLottoPuntoControlloRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatIndServLottoPuntoControlloRequest === null || getListaStatIndServLottoPuntoControlloRequest === undefined) {
            throw new Error('Required parameter getListaStatIndServLottoPuntoControlloRequest was null or undefined when calling getListaStatIndServLottoPuntoControlloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatIndServLottoPuntoControlloResponse>(`${this.configuration.basePath}/api/getListaStatIndServLottoPuntoControllo`,
            getListaStatIndServLottoPuntoControlloRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatNonConfIndicatoriRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatNonConfIndicatoriUsingPOST(getListaStatNonConfIndicatoriRequest: GetListaStatNonConfIndicatoriRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatNonConfIndicatoriResponse>;
    public getListaStatNonConfIndicatoriUsingPOST(getListaStatNonConfIndicatoriRequest: GetListaStatNonConfIndicatoriRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatNonConfIndicatoriResponse>>;
    public getListaStatNonConfIndicatoriUsingPOST(getListaStatNonConfIndicatoriRequest: GetListaStatNonConfIndicatoriRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatNonConfIndicatoriResponse>>;
    public getListaStatNonConfIndicatoriUsingPOST(getListaStatNonConfIndicatoriRequest: GetListaStatNonConfIndicatoriRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatNonConfIndicatoriRequest === null || getListaStatNonConfIndicatoriRequest === undefined) {
            throw new Error('Required parameter getListaStatNonConfIndicatoriRequest was null or undefined when calling getListaStatNonConfIndicatoriUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatNonConfIndicatoriResponse>(`${this.configuration.basePath}/api/getListaStatNonConfIndicatori`,
            getListaStatNonConfIndicatoriRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatNonConfLottoPuntoControlloRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatNonConfLottoPuntoControlloUsingPOST(getListaStatNonConfLottoPuntoControlloRequest: GetListaStatNonConfLottoPuntoControlloRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatNonConfLottoPuntoControlloResponse>;
    public getListaStatNonConfLottoPuntoControlloUsingPOST(getListaStatNonConfLottoPuntoControlloRequest: GetListaStatNonConfLottoPuntoControlloRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatNonConfLottoPuntoControlloResponse>>;
    public getListaStatNonConfLottoPuntoControlloUsingPOST(getListaStatNonConfLottoPuntoControlloRequest: GetListaStatNonConfLottoPuntoControlloRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatNonConfLottoPuntoControlloResponse>>;
    public getListaStatNonConfLottoPuntoControlloUsingPOST(getListaStatNonConfLottoPuntoControlloRequest: GetListaStatNonConfLottoPuntoControlloRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatNonConfLottoPuntoControlloRequest === null || getListaStatNonConfLottoPuntoControlloRequest === undefined) {
            throw new Error('Required parameter getListaStatNonConfLottoPuntoControlloRequest was null or undefined when calling getListaStatNonConfLottoPuntoControlloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatNonConfLottoPuntoControlloResponse>(`${this.configuration.basePath}/api/getListaStatNonConfLottoPuntoControllo`,
            getListaStatNonConfLottoPuntoControlloRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaStatisticheGlobaliRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaStatisticheGlobaliUsingPOST(getListaStatisticheGlobaliRequest: GetListaStatisticheGlobaliRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaStatisticheGlobaliResponse>;
    public getListaStatisticheGlobaliUsingPOST(getListaStatisticheGlobaliRequest: GetListaStatisticheGlobaliRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaStatisticheGlobaliResponse>>;
    public getListaStatisticheGlobaliUsingPOST(getListaStatisticheGlobaliRequest: GetListaStatisticheGlobaliRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaStatisticheGlobaliResponse>>;
    public getListaStatisticheGlobaliUsingPOST(getListaStatisticheGlobaliRequest: GetListaStatisticheGlobaliRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaStatisticheGlobaliRequest === null || getListaStatisticheGlobaliRequest === undefined) {
            throw new Error('Required parameter getListaStatisticheGlobaliRequest was null or undefined when calling getListaStatisticheGlobaliUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaStatisticheGlobaliResponse>(`${this.configuration.basePath}/api/getListaStatisticheGlobali`,
            getListaStatisticheGlobaliRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
