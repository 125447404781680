/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LottoContrattoBaseLookUp { 
    descrLotto?: string;
    idBase?: number;
    descrCliente?: string;
    descrBase?: string;
    idLotto?: number;
    idSerCon?: number;
    idCon?: number;
    codiceBase?: string;
    idSer?: number;
    idCli?: number;
    id?: number;
    idCliCon?: number;
    codiceLotto?: string;
    codiceServizio?: string;
    descrServizioContratto?: string;
    codiceCliente?: string;
    descrServizio?: string;
}


