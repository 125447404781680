/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClienteAllOf { 
    ragSoc?: string;
    annullato?: boolean;
    note?: string;
    codFiscale?: string;
    referenti?: string;
    DataDisatt?: string;
    codice?: string;
    indirizzo?: string;
    dataAttivazione?: string;
    regione?: string;
    local?: string;
    piva?: string;
    altriRecapiti?: string;
    cap?: string;
    codExt?: string;
    tel?: string;
    id?: number;
    prov?: string;
    email?: string;
    attivo?: boolean;
}


