/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatNonConfLottoPuntoControllo { 
    unitaControlloDescr?: string;
    lottoId?: number;
    baseDescr?: string;
    baseId?: number;
    periodoId?: number;
    unitaControlloId?: number;
    lottoDescr?: string;
    servizioId?: number;
    numSchedeTot?: number;
    clienteId?: number;
    puntoControlloId?: number;
    periododescr?: string;
    percSchedeNonConformi?: number;
    clienteRagSoc?: string;
    servizioDescrizione?: string;
    numSchedeNonConformi?: number;
    puntoControlloDescr?: string;
}


