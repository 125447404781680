import {Injectable} from '@angular/core';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {
  ArticoloContratto,
  ArticoloContrattoControllerService,
  ArticoloContrattoSearch,
  CreaArticoloContrattoRequest,
  EliminaArticoloContrattoRequest,
  GetArticoloContrattoRequest,
  GetListaArticoloContrattoRequest,
  ModificaArticoloContrattoRequest,
  SovrascriviListaArticoloContrattoRequest
} from '../../../api';
import {Observable, of} from 'rxjs';
import {ErrorService} from '../../../shared/core/error.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticoliContrattiService extends CrudDatasource<ArticoloContratto, ArticoloContratto, ArticoloContrattoSearch> {

  constructor(private service: ArticoloContrattoControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  protected callLoad(searchRequest: ArticoloContrattoSearch | undefined): Observable<ArticoloContratto[] | undefined> {
    if (searchRequest) {
      const req: GetListaArticoloContrattoRequest = {
        parametriRicerca: searchRequest
      };
      return this.service.getListaArticoloContrattoUsingPOST(req).pipe(
        map(res => res.listaArticoloContratto)
      );
    } else {
      return of([]);
    }
  }

  protected callGetByKey(key: any): Observable<ArticoloContratto | undefined> {
    const req: GetArticoloContrattoRequest = {
      idArticoloContratto: key
    };
    return this.service.getArticoloContrattoUsingPOST(req).pipe(
      map(res => res.articoloContratto)
    );
  }

  protected callInsert(values: ArticoloContratto): Observable<ArticoloContratto | undefined> {
    const req: CreaArticoloContrattoRequest = {
      articoloContratto: values
    };
    return this.service.creaArticoloContrattoUsingPOST(req).pipe(
      map(res => res.articoloContratto)
    );
  }

  protected callDelete(values: ArticoloContratto): Observable<any> {
    const req: EliminaArticoloContrattoRequest = {
      articoloContratto: values
    };
    return this.service.eliminaArticoloContrattoUsingPOST(req);
  }

  protected callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaArticoloContrattoRequest = {};
    return this.service.getListaArticoloContrattoUsingPOST(req).pipe(
      map(res => res.listaArticoloContratto?.length || 0)
    );
  }

  protected callUpdate(key: any, values: ArticoloContratto): Observable<ArticoloContratto | undefined> {
    // FIXME gestire update PK
    const req: ModificaArticoloContrattoRequest = {
      articoloContratto: values
    };
    return this.service.modificaArticoloContrattoUsingPOST(req).pipe(
      map(res => res.articoloContratto)
    );
  }

  getKeyField(): string {
    return 'id';
  }

  public overwriteAll(values: ArticoloContratto[], idContratto: number): Observable<ArticoloContratto[] | undefined> {
    this.reset();
    return this.callOverwriteAll(values, idContratto)
      .pipe(
        this.refreshAndErrorHandling()
      );
  }

  callOverwriteAll(values: ArticoloContratto[], idContratto: number): Observable<ArticoloContratto[] | undefined> {
    const req: SovrascriviListaArticoloContrattoRequest = {
      listaArticoloContratto: values,
      idContratto
    };
    return this.service.sovrascriviListaArticoloContrattoUsingPOST(req).pipe(
      map(res => res.listaArticoloContratto)
    );
  }

}
