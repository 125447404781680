/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatusSegnalazione = 'APERTA' | 'IN_CARICO' | 'CHIUSA_CLIENTE' | 'CHIUSA_FORNITORE';

export const StatusSegnalazione = {
    Aperta: 'APERTA' as StatusSegnalazione,
    InCarico: 'IN_CARICO' as StatusSegnalazione,
    ChiusaCliente: 'CHIUSA_CLIENTE' as StatusSegnalazione,
    ChiusaFornitore: 'CHIUSA_FORNITORE' as StatusSegnalazione
};

