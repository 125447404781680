/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RegolaCalcolo = 'S' | 'P';

export const RegolaCalcolo = {
    S: 'S' as RegolaCalcolo,
    P: 'P' as RegolaCalcolo
};

