/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

import {
  CheckUploadedFileRequest,
  CheckUploadedFileResponse,
  GeneraSchedeTesRilevazioneRequest,
  GeneraSchedeTesRilevazioneResponse,
  GenericResponse,
  GetContatoriSchedeTesRilevazioneResponse,
  GetDatiAvvioSchedeTesRilevazioneRequest,
  GetDatiAvvioSchedeTesRilevazioneResponse,
  GetListaLookUpControlloriFromGenerazioneRequest,
  GetListaLookUpControlloriFromGenerazioneResponse,
  GetListaSchedeTesRilevazioneRequest,
  GetListaSchedeTesRilevazioneResponse,
  GetSchedaTesRilevazioneRequest,
  GetSchedaTesRilevazioneResponse,
  ModificaSchedaTesRilevazioneRequest,
  ModificaSchedaTesRilevazioneResponse,
  UploadAllegatoSchedaDetRilRequest,
  UploadAllegatoSchedaDetRilResponse,
  ValidazioneMassivaTesRilevazioneRequest
} from '../model/models';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class SchedaTesRilevazioneControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param checkUploadedFileRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAllegatiSchedaDetUsingPOST(checkUploadedFileRequest: CheckUploadedFileRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CheckUploadedFileResponse>;
    public checkAllegatiSchedaDetUsingPOST(checkUploadedFileRequest: CheckUploadedFileRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CheckUploadedFileResponse>>;
    public checkAllegatiSchedaDetUsingPOST(checkUploadedFileRequest: CheckUploadedFileRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CheckUploadedFileResponse>>;
    public checkAllegatiSchedaDetUsingPOST(checkUploadedFileRequest: CheckUploadedFileRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (checkUploadedFileRequest === null || checkUploadedFileRequest === undefined) {
            throw new Error('Required parameter checkUploadedFileRequest was null or undefined when calling checkAllegatiSchedaDetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CheckUploadedFileResponse>(`${this.configuration.basePath}/api/checkAllegatoSchedaDetRil`,
            checkUploadedFileRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fileName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAllegatoSchedaDetUsingGET(fileName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Blob>;
    public downloadAllegatoSchedaDetUsingGET(fileName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Blob>>;
    public downloadAllegatoSchedaDetUsingGET(fileName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Blob>>;
    public downloadAllegatoSchedaDetUsingGET(fileName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling downloadAllegatoSchedaDetUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/downloadAllegatoSchedaDetRil/${encodeURIComponent(String(fileName))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param generaSchedeTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generaSchedeTesRilevazioneUsingPOST(generaSchedeTesRilevazioneRequest: GeneraSchedeTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GeneraSchedeTesRilevazioneResponse>;
    public generaSchedeTesRilevazioneUsingPOST(generaSchedeTesRilevazioneRequest: GeneraSchedeTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GeneraSchedeTesRilevazioneResponse>>;
    public generaSchedeTesRilevazioneUsingPOST(generaSchedeTesRilevazioneRequest: GeneraSchedeTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GeneraSchedeTesRilevazioneResponse>>;
    public generaSchedeTesRilevazioneUsingPOST(generaSchedeTesRilevazioneRequest: GeneraSchedeTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (generaSchedeTesRilevazioneRequest === null || generaSchedeTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter generaSchedeTesRilevazioneRequest was null or undefined when calling generaSchedeTesRilevazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GeneraSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/generaSchedeTesRilevazione`,
            generaSchedeTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param body requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContatoriSchedeTesRilValidazioneUsingPOST(body: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetContatoriSchedeTesRilevazioneResponse>;
    public getContatoriSchedeTesRilValidazioneUsingPOST(body: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetContatoriSchedeTesRilevazioneResponse>>;
    public getContatoriSchedeTesRilValidazioneUsingPOST(body: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetContatoriSchedeTesRilevazioneResponse>>;
    public getContatoriSchedeTesRilValidazioneUsingPOST(body: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getContatoriSchedeTesRilValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetContatoriSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/getContatoriSchedeTesRilValidazione`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getDatiAvvioSchedeTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDatiAvvioSchedeTesRilevazioneUsingPOST(getDatiAvvioSchedeTesRilevazioneRequest: GetDatiAvvioSchedeTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetDatiAvvioSchedeTesRilevazioneResponse>;
    public getDatiAvvioSchedeTesRilevazioneUsingPOST(getDatiAvvioSchedeTesRilevazioneRequest: GetDatiAvvioSchedeTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetDatiAvvioSchedeTesRilevazioneResponse>>;
    public getDatiAvvioSchedeTesRilevazioneUsingPOST(getDatiAvvioSchedeTesRilevazioneRequest: GetDatiAvvioSchedeTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetDatiAvvioSchedeTesRilevazioneResponse>>;
    public getDatiAvvioSchedeTesRilevazioneUsingPOST(getDatiAvvioSchedeTesRilevazioneRequest: GetDatiAvvioSchedeTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getDatiAvvioSchedeTesRilevazioneRequest === null || getDatiAvvioSchedeTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getDatiAvvioSchedeTesRilevazioneRequest was null or undefined when calling getDatiAvvioSchedeTesRilevazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetDatiAvvioSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/getDatiAvvioSchedeTesRilevazione`,
            getDatiAvvioSchedeTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaLookUpControlloriFromGenerazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaLookUpControlloriFromGenerazioneUsingPOST(getListaLookUpControlloriFromGenerazioneRequest: GetListaLookUpControlloriFromGenerazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaLookUpControlloriFromGenerazioneResponse>;
    public getListaLookUpControlloriFromGenerazioneUsingPOST(getListaLookUpControlloriFromGenerazioneRequest: GetListaLookUpControlloriFromGenerazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaLookUpControlloriFromGenerazioneResponse>>;
    public getListaLookUpControlloriFromGenerazioneUsingPOST(getListaLookUpControlloriFromGenerazioneRequest: GetListaLookUpControlloriFromGenerazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaLookUpControlloriFromGenerazioneResponse>>;
    public getListaLookUpControlloriFromGenerazioneUsingPOST(getListaLookUpControlloriFromGenerazioneRequest: GetListaLookUpControlloriFromGenerazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaLookUpControlloriFromGenerazioneRequest === null || getListaLookUpControlloriFromGenerazioneRequest === undefined) {
            throw new Error('Required parameter getListaLookUpControlloriFromGenerazioneRequest was null or undefined when calling getListaLookUpControlloriFromGenerazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaLookUpControlloriFromGenerazioneResponse>(`${this.configuration.basePath}/api/getListaLookUpControlloriFromGenerazione`,
            getListaLookUpControlloriFromGenerazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaSchedeTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaSchedeTesRilevazioneFromCompilazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaSchedeTesRilevazioneResponse>;
    public getListaSchedeTesRilevazioneFromCompilazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromCompilazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromCompilazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaSchedeTesRilevazioneRequest === null || getListaSchedeTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getListaSchedeTesRilevazioneRequest was null or undefined when calling getListaSchedeTesRilevazioneFromCompilazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/getListaSchedeTesRilevazioneFromCompilazione`,
            getListaSchedeTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaSchedeTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaSchedeTesRilevazioneFromGenerazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaSchedeTesRilevazioneResponse>;
    public getListaSchedeTesRilevazioneFromGenerazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromGenerazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromGenerazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaSchedeTesRilevazioneRequest === null || getListaSchedeTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getListaSchedeTesRilevazioneRequest was null or undefined when calling getListaSchedeTesRilevazioneFromGenerazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/getListaSchedeTesRilevazioneFromGenerazione`,
            getListaSchedeTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaSchedeTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaSchedeTesRilevazioneFromValidazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaSchedeTesRilevazioneResponse>;
    public getListaSchedeTesRilevazioneFromValidazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromValidazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaSchedeTesRilevazioneResponse>>;
    public getListaSchedeTesRilevazioneFromValidazioneUsingPOST(getListaSchedeTesRilevazioneRequest: GetListaSchedeTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaSchedeTesRilevazioneRequest === null || getListaSchedeTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getListaSchedeTesRilevazioneRequest was null or undefined when calling getListaSchedeTesRilevazioneFromValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaSchedeTesRilevazioneResponse>(`${this.configuration.basePath}/api/getListaSchedeTesRilevazioneFromValidazione`,
            getListaSchedeTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchedaTesRilevazioneFromCompilazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSchedaTesRilevazioneResponse>;
    public getSchedaTesRilevazioneFromCompilazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSchedaTesRilevazioneResponse>>;
    public getSchedaTesRilevazioneFromCompilazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSchedaTesRilevazioneResponse>>;
    public getSchedaTesRilevazioneFromCompilazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getSchedaTesRilevazioneRequest === null || getSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getSchedaTesRilevazioneRequest was null or undefined when calling getSchedaTesRilevazioneFromCompilazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/getSchedaTesRilevazioneFromCompilazione`,
            getSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchedaTesRilevazioneFromValidazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSchedaTesRilevazioneResponse>;
    public getSchedaTesRilevazioneFromValidazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSchedaTesRilevazioneResponse>>;
    public getSchedaTesRilevazioneFromValidazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSchedaTesRilevazioneResponse>>;
    public getSchedaTesRilevazioneFromValidazioneUsingPOST(getSchedaTesRilevazioneRequest: GetSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getSchedaTesRilevazioneRequest === null || getSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter getSchedaTesRilevazioneRequest was null or undefined when calling getSchedaTesRilevazioneFromValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/getSchedaTesRilevazioneFromValidazione`,
            getSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaSchedaTesRilevazioneResponse>;
    public modificaSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaSchedaTesRilevazioneRequest === null || modificaSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter modificaSchedaTesRilevazioneRequest was null or undefined when calling modificaSchedaTesRilevazioneFromCompilazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/modificaSchedaTesRilevazioneFromCompilazione`,
            modificaSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaSchedaTesRilevazioneFromGenerazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaSchedaTesRilevazioneResponse>;
    public modificaSchedaTesRilevazioneFromGenerazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromGenerazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromGenerazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaSchedaTesRilevazioneRequest === null || modificaSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter modificaSchedaTesRilevazioneRequest was null or undefined when calling modificaSchedaTesRilevazioneFromGenerazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/modificaSchedaTesRilevazioneFromGenerazione`,
            modificaSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaSchedaTesRilevazioneResponse>;
    public modificaSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaSchedaTesRilevazioneResponse>>;
    public modificaSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaSchedaTesRilevazioneRequest === null || modificaSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter modificaSchedaTesRilevazioneRequest was null or undefined when calling modificaSchedaTesRilevazioneFromValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/modificaSchedaTesRilevazioneFromValidazione`,
            modificaSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaSchedaTesRilevazioneResponse>;
    public resetSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaSchedaTesRilevazioneResponse>>;
    public resetSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaSchedaTesRilevazioneResponse>>;
    public resetSchedaTesRilevazioneFromCompilazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaSchedaTesRilevazioneRequest === null || modificaSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter modificaSchedaTesRilevazioneRequest was null or undefined when calling resetSchedaTesRilevazioneFromCompilazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/resetSchedaTesRilevazioneFromCompilazione`,
            modificaSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaSchedaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaSchedaTesRilevazioneResponse>;
    public resetSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaSchedaTesRilevazioneResponse>>;
    public resetSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaSchedaTesRilevazioneResponse>>;
    public resetSchedaTesRilevazioneFromValidazioneUsingPOST(modificaSchedaTesRilevazioneRequest: ModificaSchedaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaSchedaTesRilevazioneRequest === null || modificaSchedaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter modificaSchedaTesRilevazioneRequest was null or undefined when calling resetSchedaTesRilevazioneFromValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaSchedaTesRilevazioneResponse>(`${this.configuration.basePath}/api/resetSchedaTesRilevazioneFromValidazione`,
            modificaSchedaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uploadAllegatoSchedaDetRilRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFileHandlerUsingPOST(uploadAllegatoSchedaDetRilRequest: UploadAllegatoSchedaDetRilRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UploadAllegatoSchedaDetRilResponse>;
    public uploadFileHandlerUsingPOST(uploadAllegatoSchedaDetRilRequest: UploadAllegatoSchedaDetRilRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UploadAllegatoSchedaDetRilResponse>>;
    public uploadFileHandlerUsingPOST(uploadAllegatoSchedaDetRilRequest: UploadAllegatoSchedaDetRilRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UploadAllegatoSchedaDetRilResponse>>;
    public uploadFileHandlerUsingPOST(uploadAllegatoSchedaDetRilRequest: UploadAllegatoSchedaDetRilRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uploadAllegatoSchedaDetRilRequest === null || uploadAllegatoSchedaDetRilRequest === undefined) {
            throw new Error('Required parameter uploadAllegatoSchedaDetRilRequest was null or undefined when calling uploadFileHandlerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UploadAllegatoSchedaDetRilResponse>(`${this.configuration.basePath}/api/uploadAllegatoSchedaDetRil`,
            uploadAllegatoSchedaDetRilRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param validazioneMassivaTesRilevazioneRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validazioneMassivaSchedeTesRilevazioneFromValidazioneUsingPOST(validazioneMassivaTesRilevazioneRequest: ValidazioneMassivaTesRilevazioneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GenericResponse>;
    public validazioneMassivaSchedeTesRilevazioneFromValidazioneUsingPOST(validazioneMassivaTesRilevazioneRequest: ValidazioneMassivaTesRilevazioneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GenericResponse>>;
    public validazioneMassivaSchedeTesRilevazioneFromValidazioneUsingPOST(validazioneMassivaTesRilevazioneRequest: ValidazioneMassivaTesRilevazioneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GenericResponse>>;
    public validazioneMassivaSchedeTesRilevazioneFromValidazioneUsingPOST(validazioneMassivaTesRilevazioneRequest: ValidazioneMassivaTesRilevazioneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (validazioneMassivaTesRilevazioneRequest === null || validazioneMassivaTesRilevazioneRequest === undefined) {
            throw new Error('Required parameter validazioneMassivaTesRilevazioneRequest was null or undefined when calling validazioneMassivaSchedeTesRilevazioneFromValidazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GenericResponse>(`${this.configuration.basePath}/api/validazioneMassivaSchedaTesRilevazione`,
            validazioneMassivaTesRilevazioneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
