/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TipoServizio = 'PER_LOTTO' | 'PER_SERVIZIO' | 'PER_BASE';

export const TipoServizio = {
    Lotto: 'PER_LOTTO' as TipoServizio,
    Servizio: 'PER_SERVIZIO' as TipoServizio,
    Base: 'PER_BASE' as TipoServizio
};

