/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatNVElencoNonValutabili { 
    elementoDescr?: string;
    totali?: number;
    lottoId?: number;
    indicatoreDescr?: string;
    indicatoreId?: number;
    baseDescr?: string;
    lottodescr?: string;
    controlloreDescr?: string;
    baseId?: number;
    periodoId?: number;
    servizioId?: number;
    incidenza?: number;
    nonValutabili?: number;
    periododescr?: string;
    controlloreId?: number;
    serviziodescr?: string;
    elementoId?: number;
}


