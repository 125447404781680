/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatControlliLottoPerControllore { 
    clienteId?: number;
    periododescr?: string;
    clienteRagSoc?: string;
    baseDescr?: string;
    percContrNonConformi?: number;
    baseId?: number;
    numSchedeValidate?: number;
    periodoId?: number;
    userId?: number;
    numSchedeNonConformi?: number;
    numSchedeRilevate?: number;
    userDescr?: string;
}


