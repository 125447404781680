/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UtenteGruppoUOAllOf { 
    id_SerCliCon?: number;
    flag_attivazione: boolean;
    statoOrdinamento?: number;
    descrizioneGruppoUO?: string;
    flag_notifica_push: boolean;
    cod_stato?: string;
    id_gruppoUnitaOp?: number;
    statoDescrizione?: string;
    utenteLogin?: string;
    id_utente?: number;
    flag_email: boolean;
    codiceGruppoUO?: string;
}


