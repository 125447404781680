/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContrattiSearch { 
    idServizio?: number;
    idAccordoQuadro?: number;
    idClassificazioneCliente?: string;
    cercaOvunque?: string;
    numero?: string;
    idClassificazioneFornitore?: string;
    oggetto?: string;
    indEscsFornitore?: boolean;
    dataStipulaA?: string;
    dataStipulaDa?: string;
    dataAttivazioneA?: string;
    dataScadenzaA?: string;
    indEscsCliente?: boolean;
    idCliente?: number;
    regioneFornitore?: string;
    idFornitore?: number;
    idValoreClassCliente?: string;
    idValoreClassFornitore?: string;
    dataAttivazioneDa?: string;
    dataScadenzaDa?: string;
    regioneCliente?: string;
}


