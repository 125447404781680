/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type F = 'id' | 'idCon' | 'numeroContratto' | 'idCliCon' | 'idCli' | 'clienteRagSoc' | 'idFor' | 'fornitoreRagSoc' | 'idSerCon' | 'serContratto' | 'idSer' | 'servizio' | 'allegato' | 'progressivo' | 'note';

export const F = {
    Id: 'id' as F,
    IdCon: 'idCon' as F,
    NumeroContratto: 'numeroContratto' as F,
    IdCliCon: 'idCliCon' as F,
    IdCli: 'idCli' as F,
    ClienteRagSoc: 'clienteRagSoc' as F,
    IdFor: 'idFor' as F,
    FornitoreRagSoc: 'fornitoreRagSoc' as F,
    IdSerCon: 'idSerCon' as F,
    SerContratto: 'serContratto' as F,
    IdSer: 'idSer' as F,
    Servizio: 'servizio' as F,
    Allegato: 'allegato' as F,
    Progressivo: 'progressivo' as F,
    Note: 'note' as F
};

