/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaSegnalatoriSegn { 
    contratto?: string;
    segnTot?: number;
    mediaCfor?: string;
    mediaAper?: string;
    mediaCCli?: string;
    segnPcar?: number;
    segnCfor?: number;
    segnAper?: number;
    servizio?: string;
    nf?: object;
    mediaPcar?: string;
    segnCcli?: number;
    descUser?: string;
}


