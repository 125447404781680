/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatisticheNonConformitaSearch { 
    idPtoStocc?: number;
    idSerContratto?: number;
    idCliContratto?: number;
    idLotto?: number;
    idPeriodo?: number;
    idBaseCli?: number;
}


