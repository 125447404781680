/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

import {
  CreaPuntoStoccaggioRequest,
  CreaPuntoStoccaggioResponse,
  EliminaPuntoStoccaggioRequest,
  EmptyResponse,
  GetListaLookUpPuntiStoccaggioResponse,
  GetListaPuntiStoccaggioRequest,
  GetListaPuntiStoccaggioResponse,
  GetPuntoStoccaggioRequest,
  GetPuntoStoccaggioResponse,
  ModificaPuntoStoccaggioRequest,
  ModificaPuntoStoccaggioResponse
} from '../model/models';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class PuntiStoccaggioControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param creaPuntoStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creaPuntoStoccaggioUsingPOST(creaPuntoStoccaggioRequest: CreaPuntoStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreaPuntoStoccaggioResponse>;
    public creaPuntoStoccaggioUsingPOST(creaPuntoStoccaggioRequest: CreaPuntoStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreaPuntoStoccaggioResponse>>;
    public creaPuntoStoccaggioUsingPOST(creaPuntoStoccaggioRequest: CreaPuntoStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreaPuntoStoccaggioResponse>>;
    public creaPuntoStoccaggioUsingPOST(creaPuntoStoccaggioRequest: CreaPuntoStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (creaPuntoStoccaggioRequest === null || creaPuntoStoccaggioRequest === undefined) {
            throw new Error('Required parameter creaPuntoStoccaggioRequest was null or undefined when calling creaPuntoStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreaPuntoStoccaggioResponse>(`${this.configuration.basePath}/api/creaPuntoStoccaggio`,
            creaPuntoStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param eliminaPuntoStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eliminaPuntoStoccaggioUsingPOST(eliminaPuntoStoccaggioRequest: EliminaPuntoStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<EmptyResponse>;
    public eliminaPuntoStoccaggioUsingPOST(eliminaPuntoStoccaggioRequest: EliminaPuntoStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<EmptyResponse>>;
    public eliminaPuntoStoccaggioUsingPOST(eliminaPuntoStoccaggioRequest: EliminaPuntoStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<EmptyResponse>>;
    public eliminaPuntoStoccaggioUsingPOST(eliminaPuntoStoccaggioRequest: EliminaPuntoStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (eliminaPuntoStoccaggioRequest === null || eliminaPuntoStoccaggioRequest === undefined) {
            throw new Error('Required parameter eliminaPuntoStoccaggioRequest was null or undefined when calling eliminaPuntoStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EmptyResponse>(`${this.configuration.basePath}/api/eliminaPuntoStoccaggio`,
            eliminaPuntoStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaPuntiStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaLookUpPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaLookUpPuntiStoccaggioResponse>;
    public getListaLookUpPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaLookUpPuntiStoccaggioResponse>>;
    public getListaLookUpPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaLookUpPuntiStoccaggioResponse>>;
    public getListaLookUpPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaPuntiStoccaggioRequest === null || getListaPuntiStoccaggioRequest === undefined) {
            throw new Error('Required parameter getListaPuntiStoccaggioRequest was null or undefined when calling getListaLookUpPuntiStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaLookUpPuntiStoccaggioResponse>(`${this.configuration.basePath}/api/getListaLookUpPuntiStoccaggio`,
            getListaPuntiStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getListaPuntiStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListaPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetListaPuntiStoccaggioResponse>;
    public getListaPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetListaPuntiStoccaggioResponse>>;
    public getListaPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetListaPuntiStoccaggioResponse>>;
    public getListaPuntiStoccaggioUsingPOST(getListaPuntiStoccaggioRequest: GetListaPuntiStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getListaPuntiStoccaggioRequest === null || getListaPuntiStoccaggioRequest === undefined) {
            throw new Error('Required parameter getListaPuntiStoccaggioRequest was null or undefined when calling getListaPuntiStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetListaPuntiStoccaggioResponse>(`${this.configuration.basePath}/api/getListaPuntiStoccaggio`,
            getListaPuntiStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param getPuntoStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPuntoStoccaggioUsingPOST(getPuntoStoccaggioRequest: GetPuntoStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetPuntoStoccaggioResponse>;
    public getPuntoStoccaggioUsingPOST(getPuntoStoccaggioRequest: GetPuntoStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetPuntoStoccaggioResponse>>;
    public getPuntoStoccaggioUsingPOST(getPuntoStoccaggioRequest: GetPuntoStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetPuntoStoccaggioResponse>>;
    public getPuntoStoccaggioUsingPOST(getPuntoStoccaggioRequest: GetPuntoStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (getPuntoStoccaggioRequest === null || getPuntoStoccaggioRequest === undefined) {
            throw new Error('Required parameter getPuntoStoccaggioRequest was null or undefined when calling getPuntoStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetPuntoStoccaggioResponse>(`${this.configuration.basePath}/api/getPuntoStoccaggio`,
            getPuntoStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param modificaPuntoStoccaggioRequest requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaPuntoStoccaggioUsingPOST(modificaPuntoStoccaggioRequest: ModificaPuntoStoccaggioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ModificaPuntoStoccaggioResponse>;
    public modificaPuntoStoccaggioUsingPOST(modificaPuntoStoccaggioRequest: ModificaPuntoStoccaggioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ModificaPuntoStoccaggioResponse>>;
    public modificaPuntoStoccaggioUsingPOST(modificaPuntoStoccaggioRequest: ModificaPuntoStoccaggioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ModificaPuntoStoccaggioResponse>>;
    public modificaPuntoStoccaggioUsingPOST(modificaPuntoStoccaggioRequest: ModificaPuntoStoccaggioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (modificaPuntoStoccaggioRequest === null || modificaPuntoStoccaggioRequest === undefined) {
            throw new Error('Required parameter modificaPuntoStoccaggioRequest was null or undefined when calling modificaPuntoStoccaggioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ModificaPuntoStoccaggioResponse>(`${this.configuration.basePath}/api/modificaPuntoStoccaggio`,
            modificaPuntoStoccaggioRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
