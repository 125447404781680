/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContrattoAllOf { 
    annullato?: boolean;
    note?: string;
    dataAcq?: string;
    idAcq?: number;
    numero?: string;
    data?: string;
    oggetto?: string;
    dataScadenza?: string;
    ragSocFornitore?: string;
    dataInizio?: string;
    codiceFornitore?: string;
    idFor?: number;
    indEscs?: boolean;
    numeroAcq?: string;
    id?: number;
}


