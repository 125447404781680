/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SchedaTesRilevazioneSearch { 
    idBase?: number;
    validateNonConformi?: boolean;
    ricercaFromCompilazione?: boolean;
    ricercaFromSincronizzazione?: boolean;
    idLotto?: number;
    ricercaFromValidazione?: boolean;
    dataRilevazioneA?: string;
    dataAssegnazioneDa?: string;
    idSerCon?: number;
    utenteRilevatore?: number;
    idCon?: number;
    utenteAssegnatario?: number;
    validateConformi?: boolean;
    daValidare?: boolean;
    contestate?: boolean;
    dataAssegnazioneA?: string;
    idPeriodo?: number;
    idCliCon?: number;
    dataRilevazioneDa?: string;
}


