/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FunzioniPrincipali = 'SERVIZI' | 'SERVIZI_RIDOTTI' | 'INDICATORI' | 'ELEMENTI_CONTROLLO' | 'TIPOLOGIE_PUNTI_STOCCAGGIO' | 'UNITA_CONTROLLO' | 'UNITA_MISURE_POPOLAZIONE_CONTROLLO' | 'CLIENTI' | 'CLASS_CLIENTI' | 'FORNITORI' | 'CLASS_FORNITORI' | 'ACCORDO_QUADRO' | 'BASI_CLIENTI' | 'CLIENTI_CONTRATTI' | 'COMPILAZIONE_SCHEDE' | 'COMPILAZIONE_SCHEDE_ALTRI' | 'CONTRATTI' | 'GENERAZIONE_SCHEDE' | 'LOTTI_CONTRATTI' | 'LOTTI_CONTRATTI_BASE' | 'LOTTI_UNITA_CONTROLLO' | 'PUNTI_STOCCAGGIO' | 'ASS_LOTTI_PUNTI' | 'RIEPILOGO_SCHEDE' | 'SCHEDE_DET' | 'SCHEDE_TES' | 'SERVIZI_CONTRATTI' | 'SERVIZI_CLIENTI_CONTRATTI' | 'SESSIONI_CONTROLLO' | 'STATISTICHE' | 'VALIDAZIONE_SCHEDE' | 'VALIDAZIONE_SCHEDE_PROPRIE' | 'GESTIONE_UTENTI' | 'GESTIONE_PROFILI' | 'UTENTE_CLIENTE_FORNITORE' | 'MODIFICA_SCHEDE_DA_VALIDARE' | 'NON_CONFORMITA' | 'STATISTICHE_NON_CONFORMITA' | 'STATISTICHE_NON_VALUTABILI' | 'STATISTICHE_CONTESTAZIONI' | 'UTILITA' | 'DOCUMENTI_DI_CONTRATTO' | 'QLIKSENSE_IMPOSTAZIONI' | 'QLIKSENSE_ACCEDI' | 'QLIKSENSE_ANALISI_DATI' | 'SEGNALAZIONI' | 'UNITA_OPERATIVE' | 'GRUPPI_UNITA_OPERATIVE' | 'UTENTI_GRUPPI_UNITA_OPERATIVE' | 'STATISTICHE_SEGNALAZIONI' | 'FORZA_SCHEDE_DA_COMPILAZIONE' | 'ELECONTROLLO_CONTRATTO' | 'INDICATORE_CONTRATTO' | 'SCHEDE_UTENTE' | 'STATISTICHE_DETTAGLIO_SCHEDE_ESPOSIZIONE_S2S' | 'ARTICOLO_CONTRATTO' | 'CRITICITA_CONTRATTO' | 'SINGLE_SIGN_ON';

export const FunzioniPrincipali = {
    Servizi: 'SERVIZI' as FunzioniPrincipali,
    ServiziRidotti: 'SERVIZI_RIDOTTI' as FunzioniPrincipali,
    Indicatori: 'INDICATORI' as FunzioniPrincipali,
    ElementiControllo: 'ELEMENTI_CONTROLLO' as FunzioniPrincipali,
    TipologiePuntiStoccaggio: 'TIPOLOGIE_PUNTI_STOCCAGGIO' as FunzioniPrincipali,
    UnitaControllo: 'UNITA_CONTROLLO' as FunzioniPrincipali,
    UnitaMisurePopolazioneControllo: 'UNITA_MISURE_POPOLAZIONE_CONTROLLO' as FunzioniPrincipali,
    Clienti: 'CLIENTI' as FunzioniPrincipali,
    ClassClienti: 'CLASS_CLIENTI' as FunzioniPrincipali,
    Fornitori: 'FORNITORI' as FunzioniPrincipali,
    ClassFornitori: 'CLASS_FORNITORI' as FunzioniPrincipali,
    AccordoQuadro: 'ACCORDO_QUADRO' as FunzioniPrincipali,
    BasiClienti: 'BASI_CLIENTI' as FunzioniPrincipali,
    ClientiContratti: 'CLIENTI_CONTRATTI' as FunzioniPrincipali,
    CompilazioneSchede: 'COMPILAZIONE_SCHEDE' as FunzioniPrincipali,
    CompilazioneSchedeAltri: 'COMPILAZIONE_SCHEDE_ALTRI' as FunzioniPrincipali,
    Contratti: 'CONTRATTI' as FunzioniPrincipali,
    GenerazioneSchede: 'GENERAZIONE_SCHEDE' as FunzioniPrincipali,
    LottiContratti: 'LOTTI_CONTRATTI' as FunzioniPrincipali,
    LottiContrattiBase: 'LOTTI_CONTRATTI_BASE' as FunzioniPrincipali,
    LottiUnitaControllo: 'LOTTI_UNITA_CONTROLLO' as FunzioniPrincipali,
    PuntiStoccaggio: 'PUNTI_STOCCAGGIO' as FunzioniPrincipali,
    AssLottiPunti: 'ASS_LOTTI_PUNTI' as FunzioniPrincipali,
    RiepilogoSchede: 'RIEPILOGO_SCHEDE' as FunzioniPrincipali,
    SchedeDet: 'SCHEDE_DET' as FunzioniPrincipali,
    SchedeTes: 'SCHEDE_TES' as FunzioniPrincipali,
    ServiziContratti: 'SERVIZI_CONTRATTI' as FunzioniPrincipali,
    ServiziClientiContratti: 'SERVIZI_CLIENTI_CONTRATTI' as FunzioniPrincipali,
    SessioniControllo: 'SESSIONI_CONTROLLO' as FunzioniPrincipali,
    Statistiche: 'STATISTICHE' as FunzioniPrincipali,
    ValidazioneSchede: 'VALIDAZIONE_SCHEDE' as FunzioniPrincipali,
    ValidazioneSchedeProprie: 'VALIDAZIONE_SCHEDE_PROPRIE' as FunzioniPrincipali,
    GestioneUtenti: 'GESTIONE_UTENTI' as FunzioniPrincipali,
    GestioneProfili: 'GESTIONE_PROFILI' as FunzioniPrincipali,
    UtenteClienteFornitore: 'UTENTE_CLIENTE_FORNITORE' as FunzioniPrincipali,
    ModificaSchedeDaValidare: 'MODIFICA_SCHEDE_DA_VALIDARE' as FunzioniPrincipali,
    NonConformita: 'NON_CONFORMITA' as FunzioniPrincipali,
    StatisticheNonConformita: 'STATISTICHE_NON_CONFORMITA' as FunzioniPrincipali,
    StatisticheNonValutabili: 'STATISTICHE_NON_VALUTABILI' as FunzioniPrincipali,
    StatisticheContestazioni: 'STATISTICHE_CONTESTAZIONI' as FunzioniPrincipali,
    Utilita: 'UTILITA' as FunzioniPrincipali,
    DocumentiDiContratto: 'DOCUMENTI_DI_CONTRATTO' as FunzioniPrincipali,
    QliksenseImpostazioni: 'QLIKSENSE_IMPOSTAZIONI' as FunzioniPrincipali,
    QliksenseAccedi: 'QLIKSENSE_ACCEDI' as FunzioniPrincipali,
    QliksenseAnalisiDati: 'QLIKSENSE_ANALISI_DATI' as FunzioniPrincipali,
    Segnalazioni: 'SEGNALAZIONI' as FunzioniPrincipali,
    UnitaOperative: 'UNITA_OPERATIVE' as FunzioniPrincipali,
    GruppiUnitaOperative: 'GRUPPI_UNITA_OPERATIVE' as FunzioniPrincipali,
    UtentiGruppiUnitaOperative: 'UTENTI_GRUPPI_UNITA_OPERATIVE' as FunzioniPrincipali,
    StatisticheSegnalazioni: 'STATISTICHE_SEGNALAZIONI' as FunzioniPrincipali,
    ForzaSchedeDaCompilazione: 'FORZA_SCHEDE_DA_COMPILAZIONE' as FunzioniPrincipali,
    ElecontrolloContratto: 'ELECONTROLLO_CONTRATTO' as FunzioniPrincipali,
    IndicatoreContratto: 'INDICATORE_CONTRATTO' as FunzioniPrincipali,
    SchedeUtente: 'SCHEDE_UTENTE' as FunzioniPrincipali,
    StatisticheDettaglioSchedeEsposizioneS2S: 'STATISTICHE_DETTAGLIO_SCHEDE_ESPOSIZIONE_S2S' as FunzioniPrincipali,
    ArticoloContratto: 'ARTICOLO_CONTRATTO' as FunzioniPrincipali,
    CriticitaContratto: 'CRITICITA_CONTRATTO' as FunzioniPrincipali,
    SingleSignOn: 'SINGLE_SIGN_ON' as FunzioniPrincipali
};

