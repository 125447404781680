/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatoValidazione = 'DA_VALIDARE' | 'VALIDATA_CONFORME' | 'VALIDATA_NON_CONFORME';

export const StatoValidazione = {
    DaValidare: 'DA_VALIDARE' as StatoValidazione,
    ValidataConforme: 'VALIDATA_CONFORME' as StatoValidazione,
    ValidataNonConforme: 'VALIDATA_NON_CONFORME' as StatoValidazione
};

