/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatSchedaDetRilS2S { 
    idSchedaDetRil?: number;
    punteggioPreventivo?: number;
    elementoControlloDescr?: string;
    indicatoreDescr?: string;
    peso?: number;
    valSoglia?: string;
    valMedioString?: string;
    valorePesato?: number;
    tipoControllo?: string;
    valMedio?: number;
    valPuntualeMedio?: number;
    nonValutabile?: boolean;
}


