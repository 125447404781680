/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatiCompilazione = 'DA_COMPILARE' | 'COMPILAZIONE_PARZIALE' | 'COMPILATA';

export const StatiCompilazione = {
    DaCompilare: 'DA_COMPILARE' as StatiCompilazione,
    CompilazioneParziale: 'COMPILAZIONE_PARZIALE' as StatiCompilazione,
    Compilata: 'COMPILATA' as StatiCompilazione
};

