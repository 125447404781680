import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ArticoloContratto,
  ArticoloContrattoControllerService,
  ArticoloContrattoSearch,
  GetListaArticoloContrattoRequest,
  SovrascriviListaArticoloContrattoRequest,
  UnitaControllo,
  UnitaControlloControllerService
} from '../../../api';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {GrantsService, UserSubFunctionGrants} from '../../../shared/core/grants.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {map} from "rxjs/operators";
import {ErrorService} from "../../../shared/core/error.service";
import {ArticoliContrattiService} from './articoli-contratti.service';

@Component({
  selector: 'app-articoli-contratti',
  templateUrl: './articoli-contratti.component.html',
  styleUrls: ['./articoli-contratti.component.scss']
})
export class ArticoliContrattiComponent implements OnInit, OnDestroy {

  idCon?: number;
  updateEnabled = true;
  updateClickable = false;

  private subscription: Subscription = new Subscription();

  completeArticoli!: UnitaControllo[];
  availableArticoli!: UnitaControllo[];
  selectedArticoli!: UnitaControllo[];
  originalSelectedArticoli!: UnitaControllo[];
  editingEnabled: boolean = false;
  userGrants!: UserSubFunctionGrants;
  articoliContratto$: BehaviorSubject<UnitaControllo[]> = new BehaviorSubject<UnitaControllo[]>([]);

  constructor(private grantsService: GrantsService,
              dialogService: DialogService,
              editService: ArticoliContrattiService,
              private errorService: ErrorService,
              private unitaControlloControllerService: UnitaControlloControllerService,
              private articoloContrattoControllerService: ArticoloContrattoControllerService) {
  }

  public getFunctionCode(): string {
    return 'INC';
  }

  ngOnInit(): void {
    this.loadUserGrants(this.getFunctionCode());
    if (!this.idCon) {
      this.availableArticoli = [];
      this.selectedArticoli = [];
    }


    let listaLookUpArticoliUsingPOST = this.unitaControlloControllerService.getListaLookUpUnitaControlloUsingPOST({});
    this.subscription.add(
      combineLatest([listaLookUpArticoliUsingPOST, this.articoliContratto$])
        .subscribe(values => {
          this.completeArticoli = values[0].unitaControlloLookUp || [];
          if (values[1] && this.idCon) {
            this.originalSelectedArticoli = values[1];
            this.processArticoli(this.originalSelectedArticoli);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  postInit(): void {
    this.updateEnabled = this.userGrants.update;
  }

  private loadUserGrants(fnCode: string): void {
    this.grantsService.getFunctionGrants(fnCode).subscribe(value => {
      this.userGrants = value;
      this.postInit();
    });
  }

  private loadArticoliContratto(searchRequest: ArticoloContrattoSearch | undefined): Observable<ArticoloContratto[] | undefined> {
    if (searchRequest) {
      const req: GetListaArticoloContrattoRequest = {
        parametriRicerca: searchRequest
      };
      return this.articoloContrattoControllerService.getListaArticoloContrattoUsingPOST(req).pipe(
        map(res => res.listaArticoloContratto)
      );
    } else {
      return of([]);
    }
  }

  fillNewRecord(newRecord: ArticoloContratto): void {
    newRecord.idContratto = this.idCon;
  }

  saveList(): void {
    if (this.idCon) {
      const values: ArticoloContratto[] = [];

      this.selectedArticoli.forEach(articolo => {
        const value: ArticoloContratto = {
          idContratto: this.idCon,
          idUnitaControllo: articolo.id
        };
        values.push(value);
      });
      this.subscription.add(
        this.overwriteAll(values, this.idCon).subscribe(
          () => {
            this.editingEnabled = false;
          }, (jsonKoResult) => {
            this.errorService.showErrorDialog(jsonKoResult);
          }
        )
      );
    }
  }

  private overwriteAll(values: UnitaControllo[], idContratto: number): Observable<ArticoloContratto[] | undefined> {
    const req: SovrascriviListaArticoloContrattoRequest = {
      listaArticoloContratto: values,
      idContratto
    };
    return this.articoloContrattoControllerService.sovrascriviListaArticoloContrattoUsingPOST(req).pipe(
      map(res => res.listaArticoloContratto)
    );
  }

  handleSearch(idCon: number): void {
    this.idCon = idCon;
    if (idCon > 0) {
      this.loadArticoliContratto({
        idContratto: this.idCon
      }).subscribe(value => {
        this.articoliContratto$.next(value || [])
      });
      this.updateClickable = true;
    } else {
      this.availableArticoli = [];
      this.selectedArticoli = [];

      this.updateClickable = false;
    }
  }

  private processArticoli(data: ArticoloContratto[]): void {
    const availableArticoli: UnitaControllo[] = [];
    const selectedArticoli: UnitaControllo[] = [];

    this.completeArticoli.forEach(articolo => {
      let found = false;
      data.forEach(articoloContratto => {
        if (articolo.id === articoloContratto.idUnitaControllo) {
          found = true;
        }
      });
      if (found) {
        selectedArticoli.push(articolo);
      } else {
        availableArticoli.push(articolo);
      }
    });

    this.availableArticoli = availableArticoli.sort((a, b) => (a.codice || '').localeCompare(b.codice || '') || 0);
    this.selectedArticoli = selectedArticoli.sort((a, b) => (a.codice || '').localeCompare(b.codice || '') || 0);
  }

  editRecord(): void {
    this.editingEnabled = true;
  }

  cancelEditing(): void {
    this.processArticoli(this.originalSelectedArticoli);
    this.editingEnabled = false;
  }
}
