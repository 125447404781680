/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PuntoStoccaggioAllOf { 
    note?: string;
    idSerCliCon?: number;
    codice?: string;
    ubicazione?: string;
    idSerCon?: number;
    tipoStoc?: number;
    idCon?: number;
    descr?: string;
    idSer?: number;
    idCli?: number;
    id?: number;
    idCliCon?: number;
    codiceTipoStoc?: string;
    descrTipoStoc?: string;
    idBaseCli?: number;
}


