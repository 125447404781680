/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientiSearch { 
    ragSoc?: string;
    cercaOvunque?: string;
    codice?: string;
    mostraFittizio?: boolean;
    idSchedaTesRil?: number;
    prov?: string;
    regione?: string;
    local?: string;
}


