/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LottoContrattoBaseAllOf { 
    idBase?: number;
    note?: string;
    dataFineVal?: string;
    descrizioneLotto?: string;
    numContr?: number;
    nr?: number;
    popControllo?: number;
    idLotto?: number;
    idSerCon?: number;
    idCon?: number;
    idCli?: number;
    idSer?: number;
    na?: number;
    dataIniVal?: string;
    idUMPop?: number;
    id?: number;
    idCliCon?: number;
    codiceLotto?: string;
}


