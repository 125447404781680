/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TipoNonConformitaEnum = 'MIN' | 'MAGG' | 'CRIT';

export const TipoNonConformitaEnum = {
    Min: 'MIN' as TipoNonConformitaEnum,
    Magg: 'MAGG' as TipoNonConformitaEnum,
    Crit: 'CRIT' as TipoNonConformitaEnum
};

