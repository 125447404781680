export * from './accordoQuadro';
export * from './accordoQuadroAllOf';
export * from './accordoQuadroLookUp';
export * from './adminDeleteSchedaTesRilRequest';
export * from './adminDeleteSchedaTesRilRequestAllOf';
export * from './aggiornaUtenteTokenRequest';
export * from './aggiornaUtenteTokenResponse';
export * from './allegatoPerUtente';
export * from './allegatoPerUtenteAllOf';
export * from './allegatoServizioContratto';
export * from './allegatoServizioContrattoAllOf';
export * from './allegatoServizioContrattoSearch';
export * from './allegatoServizioContrattoSearchAllOf';
export * from './articoloContratto';
export * from './articoloContrattoAllOf';
export * from './articoloContrattoSearch';
export * from './assLottiPuntiSearch';
export * from './assLottoPunto';
export * from './azioniCorrettive';
export * from './azioniCorrettiveAllOf';
export * from './azioniCorrettiveLookUp';
export * from './azioniCorrettiveSearch';
export * from './baseCliente';
export * from './baseClienteAllOf';
export * from './baseClienteLookUp';
export * from './basiClientiSearch';
export * from './cap';
export * from './capRequestBean';
export * from './capRequestBeanAllOf';
export * from './capResponseBean';
export * from './capResponseBeanAllOf';
export * from './checkUploadedFileRequest';
export * from './checkUploadedFileRequestAllOf';
export * from './checkUploadedFileResponse';
export * from './checkUploadedFileResponseAllOf';
export * from './classificazioneCliente';
export * from './classificazioneClienteAllOf';
export * from './classificazioneClienteLookUp';
export * from './classificazioneFornitore';
export * from './classificazioneFornitoreLookUp';
export * from './cliente';
export * from './clienteAllOf';
export * from './clienteClass';
export * from './clienteContratto';
export * from './clienteContrattoAllOf';
export * from './clienteContrattoLookUp';
export * from './clienteContrattoSearch';
export * from './clienteLookUp';
export * from './clientiClassSearch';
export * from './clientiSearch';
export * from './comuni';
export * from './comuniRequestBean';
export * from './comuniRequestBeanAllOf';
export * from './comuniResponseBean';
export * from './comuniResponseBeanAllOf';
export * from './concurrencyChecked';
export * from './contatoreSchedeTesRilValidazione';
export * from './contatoreSegnalazioni';
export * from './contrattiSearch';
export * from './contratto';
export * from './contrattoAllOf';
export * from './contrattoLookUp';
export * from './creaAccordoQuadroRequest';
export * from './creaAccordoQuadroRequestAllOf';
export * from './creaAccordoQuadroResponse';
export * from './creaAllegatoServizioContrattoRequest';
export * from './creaAllegatoServizioContrattoResponse';
export * from './creaArticoloContrattoRequest';
export * from './creaArticoloContrattoResponse';
export * from './creaAzioniCorrettiveRequest';
export * from './creaAzioniCorrettiveResponse';
export * from './creaBaseClienteRequest';
export * from './creaBaseClienteResponse';
export * from './creaClassificazioneClienteRequest';
export * from './creaClassificazioneClienteResponse';
export * from './creaClassificazioneFornitoreRequest';
export * from './creaClassificazioneFornitoreResponse';
export * from './creaClienteContrattoRequest';
export * from './creaClienteContrattoResponse';
export * from './creaClienteRequest';
export * from './creaClienteRequestAllOf';
export * from './creaClienteResponse';
export * from './creaContrattoRequest';
export * from './creaContrattoResponse';
export * from './creaCriticitaContrattoRequest';
export * from './creaCriticitaContrattoResponse';
export * from './creaElementoControlloRequest';
export * from './creaElementoControlloResponse';
export * from './creaFornitoreRequest';
export * from './creaFornitoreResponse';
export * from './creaGruppoUnitaOperativaRequest';
export * from './creaGruppoUnitaOperativaRequestAllOf';
export * from './creaGruppoUnitaOperativaResponse';
export * from './creaIframeRequest';
export * from './creaIframeResponse';
export * from './creaIframeVistaQLikExRequest';
export * from './creaIframeVistaQLikExResponse';
export * from './creaIframeVistaQLikExResponseAllOf';
export * from './creaIframeVistaQLikRequest';
export * from './creaIframeVistaQLikRequestAllOf';
export * from './creaIframeVistaQLikResponse';
export * from './creaIndicatoreContrattoRequest';
export * from './creaIndicatoreContrattoResponse';
export * from './creaIndicatoreContrattoResponseAllOf';
export * from './creaIndicatoreRequest';
export * from './creaIndicatoreResponse';
export * from './creaIndicatoreResponseAllOf';
export * from './creaLottiContrattiBaseResponse';
export * from './creaLottoContrattoBaseRequest';
export * from './creaLottoContrattoRequest';
export * from './creaLottoContrattoResponse';
export * from './creaLottoUnitaControlloRequest';
export * from './creaLottoUnitaControlloResponse';
export * from './creaLottoUnitaControlloResponseAllOf';
export * from './creaNonConformitaRequest';
export * from './creaNonConformitaResponse';
export * from './creaNonConformitaResponseAllOf';
export * from './creaPeriodoRequest';
export * from './creaPeriodoResponse';
export * from './creaPeriodoResponseAllOf';
export * from './creaPuntoStoccaggioRequest';
export * from './creaPuntoStoccaggioResponse';
export * from './creaSchedaDetRequest';
export * from './creaSchedaDetResponse';
export * from './creaSchedaTesRequest';
export * from './creaSchedaTesResponse';
export * from './creaSegnalazioneRequest';
export * from './creaSegnalazioneRequestAllOf';
export * from './creaSegnalazioneResponse';
export * from './creaServizioClienteContrattoBaseRequest';
export * from './creaServizioClienteContrattoBaseResponse';
export * from './creaServizioClienteContrattoRequest';
export * from './creaServizioClienteContrattoResponse';
export * from './creaServizioContrattoRequest';
export * from './creaServizioContrattoRequestAllOf';
export * from './creaServizioContrattoResponse';
export * from './creaServizioRequest';
export * from './creaServizioResponse';
export * from './creaStoricoSegnalazioneRequest';
export * from './creaStoricoSegnalazioneResponse';
export * from './creaTipistoccRequest';
export * from './creaTipistoccResponse';
export * from './creaTokenRequest';
export * from './creaTokenRequestAllOf';
export * from './creaTokenResponse';
export * from './creaUmpopcontrolloRequest';
export * from './creaUmpopcontrolloResponse';
export * from './creaUnitaControlloRequest';
export * from './creaUnitaControlloResponse';
export * from './creaUnitaOperativaRequest';
export * from './creaUnitaOperativaResponse';
export * from './creaUtenteClienteFornitoreRequest';
export * from './creaUtenteClienteFornitoreRequestAllOf';
export * from './creaUtenteClienteFornitoreResponse';
export * from './creaUtenteGruppoUoRequest';
export * from './creaUtenteGruppoUoRequestAllOf';
export * from './creaUtenteGruppoUoResponse';
export * from './creaUtentiVisteQLikRequest';
export * from './creaUtentiVisteQLikResponse';
export * from './creaValoreClassClienteRequest';
export * from './creaValoreClassClienteResponse';
export * from './creaValoreClassFornitoreRequest';
export * from './creaValoreClassFornitoreResponse';
export * from './creaVisteQLikRequest';
export * from './creaVisteQLikResponse';
export * from './criticita';
export * from './criticitaAllOf';
export * from './criticitaContratto';
export * from './criticitaContrattoAllOf';
export * from './criticitaContrattoSearch';
export * from './criticitaSearch';
export * from './downloadRequest';
export * from './downloadResponse';
export * from './downloadResponseAllOf';
export * from './elementoControllo';
export * from './elementoControlloContratto';
export * from './elementoControlloContrattoAllOf';
export * from './elementoControlloContrattoRequest';
export * from './elementoControlloContrattoResponse';
export * from './elementoControlloContrattoSearch';
export * from './elementoControlloLookUp';
export * from './elementoControlloSearch';
export * from './eliminaAccordoQuadroRequest';
export * from './eliminaAllegatoServizioContrattoRequest';
export * from './eliminaArticoloContrattoRequest';
export * from './eliminaAzioniCorrettiveRequest';
export * from './eliminaBaseClienteRequest';
export * from './eliminaBaseClienteRequestAllOf';
export * from './eliminaClassificazioneClienteRequest';
export * from './eliminaClassificazioneFornitoreRequest';
export * from './eliminaClienteContrattoRequest';
export * from './eliminaClienteContrattoRequestAllOf';
export * from './eliminaClienteRequest';
export * from './eliminaClienteRequestAllOf';
export * from './eliminaContrattoRequest';
export * from './eliminaCriticitaContrattoRequest';
export * from './eliminaElementoControlloContrattoRequest';
export * from './eliminaElementoControlloRequest';
export * from './eliminaFornitoreRequest';
export * from './eliminaFornitoreRequestAllOf';
export * from './eliminaGruppoUnitaOperativaRequest';
export * from './eliminaIframeRequest';
export * from './eliminaIframeVistaQLikExRequest';
export * from './eliminaIframeVistaQLikRequest';
export * from './eliminaIndicatoreContrattoRequest';
export * from './eliminaIndicatoreRequest';
export * from './eliminaLottoContrattoBaseRequest';
export * from './eliminaLottoContrattoRequest';
export * from './eliminaLottoUnitaControlloRequest';
export * from './eliminaNonConformitaRequest';
export * from './eliminaPeriodoRequest';
export * from './eliminaPuntoStoccaggioRequest';
export * from './eliminaSchedaDetRequest';
export * from './eliminaSchedaDetRequestAllOf';
export * from './eliminaSchedaTesRequest';
export * from './eliminaSegnalazioneRequest';
export * from './eliminaSegnalazioneRequestAllOf';
export * from './eliminaServizioClienteContrattoBaseRequest';
export * from './eliminaServizioClienteContrattoBaseRequestAllOf';
export * from './eliminaServizioClienteContrattoRequest';
export * from './eliminaServizioContrattoRequest';
export * from './eliminaServizioRequest';
export * from './eliminaStoricoSegnalazioneRequest';
export * from './eliminaTipistoccRequest';
export * from './eliminaUmpopcontrolloRequest';
export * from './eliminaUmpopcontrolloRequestAllOf';
export * from './eliminaUnitaControlloRequest';
export * from './eliminaUnitaOperativaRequest';
export * from './eliminaUtenteClienteFornitoreRequest';
export * from './eliminaUtenteGruppoUoRequest';
export * from './eliminaUtentiVisteQLikRequest';
export * from './eliminaValoreClassClienteRequest';
export * from './eliminaValoreClassFornitoreRequest';
export * from './eliminaVisteQLikRequest';
export * from './eliminaVisteQLikRequestAllOf';
export * from './emptyResponse';
export * from './errorCode';
export * from './esitoSegnalazione';
export * from './exposedAPIController';
export * from './exposedApiUsers';
export * from './f';
export * from './fireBaseNotificaiontResponse';
export * from './fireBaseNotification';
export * from './fireBaseNotificationData';
export * from './fireBaseNotificationRequest';
export * from './fireBaseToken';
export * from './fireBaseTokenAllOf';
export * from './fireBaseTokenLookUp';
export * from './fireBaseTokenSearch';
export * from './firma';
export * from './firmaAllOf';
export * from './firmaS2S';
export * from './firmaSearch';
export * from './fornitore';
export * from './fornitoreAllOf';
export * from './fornitoreClass';
export * from './fornitoreLookUp';
export * from './fornitoriClassSearch';
export * from './fornitoriSearch';
export * from './funzioniPrincipali';
export * from './funzioniSecondarie';
export * from './generaSchedeTesRilevazioneRequest';
export * from './generaSchedeTesRilevazioneRequestAllOf';
export * from './generaSchedeTesRilevazioneResponse';
export * from './generaSchedeTesRilevazioneResponseAllOf';
export * from './genericResponse';
export * from './getAccordoQuadroRequest';
export * from './getAccordoQuadroRequestAllOf';
export * from './getAccordoQuadroResponse';
export * from './getArticoloContrattoRequest';
export * from './getArticoloContrattoRequestAllOf';
export * from './getArticoloContrattoResponse';
export * from './getAzioniCorrettiveRequest';
export * from './getAzioniCorrettiveRequestAllOf';
export * from './getAzioniCorrettiveResponse';
export * from './getBaseClienteRequest';
export * from './getBaseClienteRequestAllOf';
export * from './getBaseClienteResponse';
export * from './getClassificazioneClienteRequest';
export * from './getClassificazioneClienteRequestAllOf';
export * from './getClassificazioneClienteResponse';
export * from './getClassificazioneFornitoreRequest';
export * from './getClassificazioneFornitoreRequestAllOf';
export * from './getClassificazioneFornitoreResponse';
export * from './getClienteContrattoRequest';
export * from './getClienteContrattoResponse';
export * from './getClienteRequest';
export * from './getClienteRequestAllOf';
export * from './getClienteResponse';
export * from './getContatoriSchedeTesRilevazioneResponse';
export * from './getContatoriSchedeTesRilevazioneResponseAllOf';
export * from './getContatoriSegnalazioniResponse';
export * from './getContatoriSegnalazioniResponseAllOf';
export * from './getContrattoRequest';
export * from './getContrattoResponse';
export * from './getContrattoResponseAllOf';
export * from './getContrattoRiepilogoRequest';
export * from './getContrattoRiepilogoRequestAllOf';
export * from './getContrattoRiepilogoResponse';
export * from './getContrattoRiepilogoResponseAllOf';
export * from './getCriticitaContrattoRequest';
export * from './getCriticitaContrattoRequestAllOf';
export * from './getCriticitaContrattoResponse';
export * from './getDatiAvvioSchedeTesRilevazioneRequest';
export * from './getDatiAvvioSchedeTesRilevazioneRequestAllOf';
export * from './getDatiAvvioSchedeTesRilevazioneResponse';
export * from './getDatiAvvioSchedeTesRilevazioneResponseAllOf';
export * from './getElementoControlloContrattoRequest';
export * from './getElementoControlloContrattoRequestAllOf';
export * from './getElementoControlloContrattoResponse';
export * from './getElementoControlloRequest';
export * from './getElementoControlloRequestAllOf';
export * from './getElementoControlloResponse';
export * from './getElementoControlloResponseAllOf';
export * from './getEnvResponseBean';
export * from './getEnvResponseBeanAllOf';
export * from './getFornitoreRequest';
export * from './getFornitoreRequestAllOf';
export * from './getFornitoreResponse';
export * from './getGruppoUnitaOperativaRequest';
export * from './getGruppoUnitaOperativaRequestAllOf';
export * from './getGruppoUnitaOperativaResponse';
export * from './getHashStatiSegnalazioniRequest';
export * from './getHashStatiSegnalazioniResponse';
export * from './getHashStatiSegnalazioniResponseAllOf';
export * from './getIframeRequest';
export * from './getIframeRequestAllOf';
export * from './getIframeResponse';
export * from './getIframeVistaQLikExRequest';
export * from './getIframeVistaQLikExRequestAllOf';
export * from './getIframeVistaQLikExResponse';
export * from './getIndicatoreContrattoRequest';
export * from './getIndicatoreContrattoRequestAllOf';
export * from './getIndicatoreContrattoResponse';
export * from './getIndicatoreRequest';
export * from './getIndicatoreRequestAllOf';
export * from './getIndicatoreResponse';
export * from './getListaAccordoQuadroResponse';
export * from './getListaAccordoQuadroResponseAllOf';
export * from './getListaAllegatiPerUtenteResponse';
export * from './getListaAllegatiPerUtenteResponseAllOf';
export * from './getListaAllegatiServizioContrattoRequest';
export * from './getListaAllegatiServizioContrattoResponse';
export * from './getListaAllegatiServizioContrattoResponseAllOf';
export * from './getListaArticoloContrattoRequest';
export * from './getListaArticoloContrattoRequestAllOf';
export * from './getListaArticoloContrattoResponse';
export * from './getListaAssLottiPuntiRequest';
export * from './getListaAssLottiPuntiRequestAllOf';
export * from './getListaAssLottiPuntiResponse';
export * from './getListaAssLottiPuntiResponseAllOf';
export * from './getListaAzioniCorrettiveRequest';
export * from './getListaAzioniCorrettiveRequestAllOf';
export * from './getListaAzioniCorrettiveResponse';
export * from './getListaAzioniCorrettiveResponseAllOf';
export * from './getListaBasiClientiRequest';
export * from './getListaBasiClientiResponse';
export * from './getListaBasiClientiResponseAllOf';
export * from './getListaClassClientiResponse';
export * from './getListaClassClientiResponseAllOf';
export * from './getListaClassFornitoriResponse';
export * from './getListaClassFornitoriResponseAllOf';
export * from './getListaClientiContrattiRequest';
export * from './getListaClientiContrattiRequestAllOf';
export * from './getListaClientiContrattiResponse';
export * from './getListaClientiContrattiResponseAllOf';
export * from './getListaClientiRequest';
export * from './getListaClientiRequestAllOf';
export * from './getListaClientiResponse';
export * from './getListaClientiResponseAllOf';
export * from './getListaContrattiRequest';
export * from './getListaContrattiRequestAllOf';
export * from './getListaContrattiResponse';
export * from './getListaContrattiResponseAllOf';
export * from './getListaCriticitaContrattoRequest';
export * from './getListaCriticitaContrattoRequestAllOf';
export * from './getListaCriticitaContrattoResponse';
export * from './getListaElementiControlloResponse';
export * from './getListaElementiControlloResponseAllOf';
export * from './getListaElementoControlloContrattoRequest';
export * from './getListaElementoControlloContrattoRequestAllOf';
export * from './getListaElementoControlloContrattoResponse';
export * from './getListaElementoControlloContrattoResponseAllOf';
export * from './getListaFornitoriRequest';
export * from './getListaFornitoriRequestAllOf';
export * from './getListaFornitoriResponse';
export * from './getListaFornitoriResponseAllOf';
export * from './getListaGruppiUORequest';
export * from './getListaGruppiUOResponse';
export * from './getListaGruppiUOResponseAllOf';
export * from './getListaIframeResponse';
export * from './getListaIframeUtentiResponse';
export * from './getListaIframeUtentiResponseAllOf';
export * from './getListaIframeVistaQLikExResponse';
export * from './getListaIframeVistaQLikExResponseAllOf';
export * from './getListaIframeVistaQLikResponse';
export * from './getListaIframeVistaQLikResponseAllOf';
export * from './getListaIndicatoreContrattoRequest';
export * from './getListaIndicatoreContrattoRequestAllOf';
export * from './getListaIndicatoreContrattoResponse';
export * from './getListaIndicatoreContrattoResponseAllOf';
export * from './getListaIndicatoriResponse';
export * from './getListaIndicatoriResponseAllOf';
export * from './getListaLookUpAccordoQuadroResponse';
export * from './getListaLookUpAccordoQuadroResponseAllOf';
export * from './getListaLookUpAllagatiServizioContrattoRequest';
export * from './getListaLookUpAllagatiServizioContrattoRequestAllOf';
export * from './getListaLookUpAllagatiServizioContrattoResponse';
export * from './getListaLookUpBasiClientiRequest';
export * from './getListaLookUpBasiClientiRequestAllOf';
export * from './getListaLookUpBasiClientiResponse';
export * from './getListaLookUpBasiClientiResponseAllOf';
export * from './getListaLookUpClassClientiResponse';
export * from './getListaLookUpClassClientiResponseAllOf';
export * from './getListaLookUpClassFornitoriResponse';
export * from './getListaLookUpClassFornitoriResponseAllOf';
export * from './getListaLookUpClientiContrattiRequest';
export * from './getListaLookUpClientiContrattiResponse';
export * from './getListaLookUpClientiContrattiResponseAllOf';
export * from './getListaLookUpClientiResponse';
export * from './getListaLookUpClientiResponseAllOf';
export * from './getListaLookUpContrattiResponse';
export * from './getListaLookUpContrattiResponseAllOf';
export * from './getListaLookUpControlloriFromGenerazioneRequest';
export * from './getListaLookUpControlloriFromGenerazioneResponse';
export * from './getListaLookUpControlloriFromGenerazioneResponseAllOf';
export * from './getListaLookUpElementiControlloRequest';
export * from './getListaLookUpElementiControlloRequestAllOf';
export * from './getListaLookUpElementiControlloResponse';
export * from './getListaLookUpElementiControlloResponseAllOf';
export * from './getListaLookUpFornitoriResponse';
export * from './getListaLookUpFornitoriResponseAllOf';
export * from './getListaLookUpGruppiUORequest';
export * from './getListaLookUpGruppiUORequestAllOf';
export * from './getListaLookUpGruppiUOResponse';
export * from './getListaLookUpGruppiUOResponseAllOf';
export * from './getListaLookUpIndicatoriRequest';
export * from './getListaLookUpIndicatoriResponse';
export * from './getListaLookUpIndicatoriResponseAllOf';
export * from './getListaLookUpLottiContrattiBaseRequest';
export * from './getListaLookUpLottiContrattiBaseResponse';
export * from './getListaLookUpLottiContrattiBaseResponseAllOf';
export * from './getListaLookUpLottiContrattiRequest';
export * from './getListaLookUpLottiContrattiRequestAllOf';
export * from './getListaLookUpLottiContrattiResponse';
export * from './getListaLookUpLottiContrattiResponseAllOf';
export * from './getListaLookUpPeriodiRequest';
export * from './getListaLookUpPeriodiRequestAllOf';
export * from './getListaLookUpPeriodiResponse';
export * from './getListaLookUpPeriodiResponseAllOf';
export * from './getListaLookUpPuntiStoccaggioResponse';
export * from './getListaLookUpPuntiStoccaggioResponseAllOf';
export * from './getListaLookUpSchedeDetResponse';
export * from './getListaLookUpSchedeDetResponseAllOf';
export * from './getListaLookUpSchedeTesResponse';
export * from './getListaLookUpSchedeTesResponseAllOf';
export * from './getListaLookUpSegnalazioniRequest';
export * from './getListaLookUpSegnalazioniRequestAllOf';
export * from './getListaLookUpSegnalazioniResponse';
export * from './getListaLookUpSegnalazioniResponseAllOf';
export * from './getListaLookUpServiziClientiContrattiRequest';
export * from './getListaLookUpServiziClientiContrattiResponse';
export * from './getListaLookUpServiziClientiContrattiResponseAllOf';
export * from './getListaLookUpServiziContrattiRequest';
export * from './getListaLookUpServiziContrattiResponse';
export * from './getListaLookUpServiziContrattiResponseAllOf';
export * from './getListaLookUpServiziResponse';
export * from './getListaLookUpServiziResponseAllOf';
export * from './getListaLookUpStoricoSegnalazioneRequest';
export * from './getListaLookUpStoricoSegnalazioneResponse';
export * from './getListaLookUpStoricoSegnalazioneResponseAllOf';
export * from './getListaLookUpTipistoccResponse';
export * from './getListaLookUpTipistoccResponseAllOf';
export * from './getListaLookUpUmpopcontrolloResponse';
export * from './getListaLookUpUmpopcontrolloResponseAllOf';
export * from './getListaLookUpUnitaControlloResponse';
export * from './getListaLookUpUnitaControlloResponseAllOf';
export * from './getListaLookUpUnitaOperativeRequest';
export * from './getListaLookUpUnitaOperativeRequestAllOf';
export * from './getListaLookUpUnitaOperativeResponse';
export * from './getListaLookUpUnitaOperativeResponseAllOf';
export * from './getListaLookUpUtenteClienteFornitoreResponse';
export * from './getListaLookUpUtenteClienteFornitoreResponseAllOf';
export * from './getListaLookUpUtentiGruppiUORequest';
export * from './getListaLookUpUtentiGruppiUORequestAllOf';
export * from './getListaLookUpUtentiGruppiUOResponse';
export * from './getListaLookUpUtentiGruppiUOResponseAllOf';
export * from './getListaLookUpUtentiSegnalazioniRequest';
export * from './getListaLookUpUtentiSegnalazioniRequestAllOf';
export * from './getListaLookUpUtentiSegnalazioniResponse';
export * from './getListaLookUpUtentiSegnalazioniResponseAllOf';
export * from './getListaLookupUnitaControlloRequest';
export * from './getListaLookupUnitaControlloRequestAllOf';
export * from './getListaLottiContrattiBaseRequest';
export * from './getListaLottiContrattiBaseRequestAllOf';
export * from './getListaLottiContrattiBaseResponse';
export * from './getListaLottiContrattiBaseResponseAllOf';
export * from './getListaLottiContrattiRequest';
export * from './getListaLottiContrattiResponse';
export * from './getListaLottiContrattiResponseAllOf';
export * from './getListaLottiUnitaControlloRequest';
export * from './getListaLottiUnitaControlloRequestAllOf';
export * from './getListaLottiUnitaControlloResponse';
export * from './getListaLottiUnitaControlloResponseAllOf';
export * from './getListaNonConformitaRequest';
export * from './getListaNonConformitaRequestAllOf';
export * from './getListaNonConformitaResponse';
export * from './getListaNonConformitaResponseAllOf';
export * from './getListaPeriodiRequest';
export * from './getListaPeriodiResponse';
export * from './getListaPeriodiResponseAllOf';
export * from './getListaPuntiStoccaggioRequest';
export * from './getListaPuntiStoccaggioRequestAllOf';
export * from './getListaPuntiStoccaggioResponse';
export * from './getListaPuntiStoccaggioResponseAllOf';
export * from './getListaRiepilogoSchedePerBaseRequest';
export * from './getListaRiepilogoSchedePerBaseRequestAllOf';
export * from './getListaRiepilogoSchedePerBaseResponse';
export * from './getListaRiepilogoSchedePerBaseResponseAllOf';
export * from './getListaRiepilogoSchedePerLottoRequest';
export * from './getListaRiepilogoSchedePerLottoRequestAllOf';
export * from './getListaRiepilogoSchedePerLottoResponse';
export * from './getListaRiepilogoSchedePerLottoResponseAllOf';
export * from './getListaSchedeDetRequest';
export * from './getListaSchedeDetRequestAllOf';
export * from './getListaSchedeDetResponse';
export * from './getListaSchedeDetResponseAllOf';
export * from './getListaSchedeTesRequest';
export * from './getListaSchedeTesRequestAllOf';
export * from './getListaSchedeTesResponse';
export * from './getListaSchedeTesResponseAllOf';
export * from './getListaSchedeTesRilevazioneRequest';
export * from './getListaSchedeTesRilevazioneRequestAllOf';
export * from './getListaSchedeTesRilevazioneResponse';
export * from './getListaSchedeTesRilevazioneResponseAllOf';
export * from './getListaSegnalazioniRequest';
export * from './getListaSegnalazioniResponse';
export * from './getListaSegnalazioniResponseAllOf';
export * from './getListaServiziClientiContrattiRequest';
export * from './getListaServiziClientiContrattiRequestAllOf';
export * from './getListaServiziClientiContrattiResponse';
export * from './getListaServiziClientiContrattiResponseAllOf';
export * from './getListaServiziContrattiRequest';
export * from './getListaServiziContrattiRequestAllOf';
export * from './getListaServiziContrattiResponse';
export * from './getListaServiziContrattiResponseAllOf';
export * from './getListaServiziResponse';
export * from './getListaServiziResponseAllOf';
export * from './getListaServizioClienteContrattoBaseLookupResponse';
export * from './getListaServizioClienteContrattoBaseLookupResponseAllOf';
export * from './getListaServizioClienteContrattoBaseRequest';
export * from './getListaServizioClienteContrattoBaseRequestAllOf';
export * from './getListaServizioClienteContrattoBaseResponse';
export * from './getListaServizioClienteContrattoBaseResponseAllOf';
export * from './getListaStatControlliLottoPerControlloreRequest';
export * from './getListaStatControlliLottoPerControlloreResponse';
export * from './getListaStatControlliLottoPerControlloreResponseAllOf';
export * from './getListaStatDettaglioSchedaConEsposizioneRequest';
export * from './getListaStatDettaglioSchedaConEsposizioneResponse';
export * from './getListaStatDettaglioSchedaConEsposizioneResponseAllOf';
export * from './getListaStatDettaglioSchedaConEsposizioneS2SRequest';
export * from './getListaStatDettaglioSchedaConEsposizioneS2SRequestAllOf';
export * from './getListaStatDettaglioSchedaConEsposizioneS2SResponse';
export * from './getListaStatDettaglioSchedaConEsposizioneS2SResponseAllOf';
export * from './getListaStatDettaglioSchedeRequest';
export * from './getListaStatDettaglioSchedeResponse';
export * from './getListaStatDettaglioSchedeResponseAllOf';
export * from './getListaStatElencoAzioniCorrettiveRequest';
export * from './getListaStatElencoAzioniCorrettiveResponse';
export * from './getListaStatElencoAzioniCorrettiveResponseAllOf';
export * from './getListaStatElencoContestazioniRequest';
export * from './getListaStatElencoContestazioniRequestAllOf';
export * from './getListaStatElencoContestazioniResponse';
export * from './getListaStatElencoContestazioniResponseAllOf';
export * from './getListaStatElencoNonConformitaRequest';
export * from './getListaStatElencoNonConformitaResponse';
export * from './getListaStatElencoNonConformitaResponseAllOf';
export * from './getListaStatElencoNonValutabiliRequest';
export * from './getListaStatElencoNonValutabiliResponse';
export * from './getListaStatElencoNonValutabiliResponseAllOf';
export * from './getListaStatIndServGlobaleTyBasePeriodoRequest';
export * from './getListaStatIndServGlobaleTyBasePeriodoResponse';
export * from './getListaStatIndServGlobaleTyBasePeriodoResponseAllOf';
export * from './getListaStatIndServGlobaleTyServizioPeriodoRequest';
export * from './getListaStatIndServGlobaleTyServizioPeriodoResponse';
export * from './getListaStatIndServGlobaleTyServizioPeriodoResponseAllOf';
export * from './getListaStatIndServLottoPeriodoRequest';
export * from './getListaStatIndServLottoPeriodoRequestAllOf';
export * from './getListaStatIndServLottoPeriodoResponse';
export * from './getListaStatIndServLottoPeriodoResponseAllOf';
export * from './getListaStatIndServLottoPuntoControlloRequest';
export * from './getListaStatIndServLottoPuntoControlloResponse';
export * from './getListaStatIndServLottoPuntoControlloResponseAllOf';
export * from './getListaStatNonConfIndicatoriRequest';
export * from './getListaStatNonConfIndicatoriResponse';
export * from './getListaStatNonConfIndicatoriResponseAllOf';
export * from './getListaStatNonConfLottoPuntoControlloRequest';
export * from './getListaStatNonConfLottoPuntoControlloResponse';
export * from './getListaStatNonConfLottoPuntoControlloResponseAllOf';
export * from './getListaStatRiepilogoAzioniCorrettiveRequest';
export * from './getListaStatRiepilogoAzioniCorrettiveRequestAllOf';
export * from './getListaStatRiepilogoAzioniCorrettiveResponse';
export * from './getListaStatRiepilogoAzioniCorrettiveResponseAllOf';
export * from './getListaStatRiepilogoContestazioniRequest';
export * from './getListaStatRiepilogoContestazioniResponse';
export * from './getListaStatRiepilogoContestazioniResponseAllOf';
export * from './getListaStatRiepilogoNonConformitaRequest';
export * from './getListaStatRiepilogoNonConformitaResponse';
export * from './getListaStatRiepilogoNonConformitaResponseAllOf';
export * from './getListaStatRiepilogoNonValutabiliRequest';
export * from './getListaStatRiepilogoNonValutabiliRequestAllOf';
export * from './getListaStatRiepilogoNonValutabiliResponse';
export * from './getListaStatRiepilogoNonValutabiliResponseAllOf';
export * from './getListaStatSegnalazioneRequest';
export * from './getListaStatSegnalazioneRequestAllOf';
export * from './getListaStatSegnalazioneResponse';
export * from './getListaStatSegnalazioneResponseAllOf';
export * from './getListaStatisticheGlobaliRequest';
export * from './getListaStatisticheGlobaliResponse';
export * from './getListaStatisticheGlobaliResponseAllOf';
export * from './getListaStoricoSegnalazioniRequest';
export * from './getListaStoricoSegnalazioniRequestAllOf';
export * from './getListaStoricoSegnalazioniResponse';
export * from './getListaStoricoSegnalazioniResponseAllOf';
export * from './getListaTipistoccResponse';
export * from './getListaTipistoccResponseAllOf';
export * from './getListaUmpopcontrolloResponse';
export * from './getListaUmpopcontrolloResponseAllOf';
export * from './getListaUnitaControlloResponse';
export * from './getListaUnitaControlloResponseAllOf';
export * from './getListaUnitaOperativeRequest';
export * from './getListaUnitaOperativeResponse';
export * from './getListaUnitaOperativeResponseAllOf';
export * from './getListaUtenteClienteFornitoreResponse';
export * from './getListaUtentiGruppiUORequest';
export * from './getListaUtentiGruppiUOResponse';
export * from './getListaUtentiGruppiUOResponseAllOf';
export * from './getListaUtentiVisteQLikNamedResponse';
export * from './getListaUtentiVisteQLikResponse';
export * from './getListaUtentiVisteQLikResponseAllOf';
export * from './getListaVisteQLikResponse';
export * from './getListaVisteQLikResponseAllOf';
export * from './getLottiContrattiResponse';
export * from './getLottoContrattoBaseRequest';
export * from './getLottoContrattoBaseRequestAllOf';
export * from './getLottoContrattoBaseResponse';
export * from './getLottoContrattoRequest';
export * from './getLottoContrattoRequestAllOf';
export * from './getLottoUnitaControlloRequest';
export * from './getLottoUnitaControlloRequestAllOf';
export * from './getLottoUnitaControlloResponse';
export * from './getMediaSegnalatoriRequest';
export * from './getMediaSegnalatoriResponse';
export * from './getMediaSegnalatoriResponseAllOf';
export * from './getMediaStatsSegnalazioneRequest';
export * from './getMeidaStatsSegnalazioneResponse';
export * from './getMeidaStatsSegnalazioneResponseAllOf';
export * from './getMenuRequestBean';
export * from './getMenuRequestBeanAllOf';
export * from './getMenuResponseBean';
export * from './getMenuResponseBeanAllOf';
export * from './getNonConformitaRequest';
export * from './getNonConformitaRequestAllOf';
export * from './getNonConformitaResponse';
export * from './getPeriodoRequest';
export * from './getPeriodoRequestAllOf';
export * from './getPeriodoResponse';
export * from './getProfiloRequest';
export * from './getProfiloRequestAllOf';
export * from './getPuntoStoccaggioRequest';
export * from './getPuntoStoccaggioRequestAllOf';
export * from './getPuntoStoccaggioResponse';
export * from './getQLikSenseJWTTokenResponse';
export * from './getQLikSenseJWTTokenResponseAllOf';
export * from './getReportSchedaListRequest';
export * from './getReportSchedaListRequestAllOf';
export * from './getRiepElemControlloRequest';
export * from './getRiepElemControlloRequestAllOf';
export * from './getRiepElemControlloResponse';
export * from './getRiepElemControlloResponseAllOf';
export * from './getSchedaDetRequest';
export * from './getSchedaDetRequestAllOf';
export * from './getSchedaDetResponse';
export * from './getSchedaTesRequest';
export * from './getSchedaTesRequestAllOf';
export * from './getSchedaTesResponse';
export * from './getSchedaTesRilevazioneRequest';
export * from './getSchedaTesRilevazioneRequestAllOf';
export * from './getSchedaTesRilevazioneResponse';
export * from './getSegnalazioneRequest';
export * from './getSegnalazioneRequestAllOf';
export * from './getSegnalazioneResponse';
export * from './getSegnalazioneResponseAllOf';
export * from './getServizioClienteContrattoBaseRequest';
export * from './getServizioClienteContrattoBaseRequestAllOf';
export * from './getServizioClienteContrattoBaseResponse';
export * from './getServizioClienteContrattoRequest';
export * from './getServizioClienteContrattoRequestAllOf';
export * from './getServizioClienteContrattoResponse';
export * from './getServizioContrattoRequest';
export * from './getServizioContrattoRequestAllOf';
export * from './getServizioContrattoResponse';
export * from './getServizioRequest';
export * from './getServizioRequestAllOf';
export * from './getServizioResponse';
export * from './getStoricoSegnalazioneRequest';
export * from './getStoricoSegnalazioneRequestAllOf';
export * from './getStoricoSegnalazioneResponse';
export * from './getStoricoSegnalazioneResponseAllOf';
export * from './getTipistoccRequest';
export * from './getTipistoccRequestAllOf';
export * from './getTipistoccResponse';
export * from './getUmpopcontrolloRequest';
export * from './getUmpopcontrolloRequestAllOf';
export * from './getUmpopcontrolloResponse';
export * from './getUnitaControlloRequest';
export * from './getUnitaControlloRequestAllOf';
export * from './getUnitaControlloResponse';
export * from './getUnitaOperativaRequest';
export * from './getUnitaOperativaRequestAllOf';
export * from './getUnitaOperativaResponse';
export * from './getUnitaOperativaResponseAllOf';
export * from './getUtenteClienteFornitoreRequest';
export * from './getUtenteClienteFornitoreRequestAllOf';
export * from './getUtenteClienteFornitoreResponse';
export * from './getUtenteGruppoUORequest';
export * from './getUtenteGruppoUORequestAllOf';
export * from './getUtenteGruppoUOResponse';
export * from './getUtenteGruppoUOResponseAllOf';
export * from './getUtentiVisteQLikRequest';
export * from './getUtentiVisteQLikRequestAllOf';
export * from './getUtentiVisteQLikResponse';
export * from './getUtentiVisteQLikResponseAllOf';
export * from './getValoreClassClienteRequest';
export * from './getValoreClassClienteRequestAllOf';
export * from './getValoreClassClienteResponse';
export * from './getValoreClassFornitoreRequest';
export * from './getValoreClassFornitoreRequestAllOf';
export * from './getValoreClassFornitoreResponse';
export * from './gruppiUnitaOperativeController';
export * from './gruppoUnitaOperativa';
export * from './gruppoUnitaOperativaAllOf';
export * from './gruppoUnitaOperativaLookUp';
export * from './gruppoUnitaOperativaSearch';
export * from './idDesc';
export * from './iframe';
export * from './iframeAllOf';
export * from './iframeVistaQLik';
export * from './iframeVistaQLikAllOf';
export * from './iframeVistaQLikEx';
export * from './iframeVistaQLikExAllOf';
export * from './indicatore';
export * from './indicatoreAllOf';
export * from './indicatoreContratto';
export * from './indicatoreContrattoAllOf';
export * from './indicatoreContrattoSearch';
export * from './indicatoreLookUp';
export * from './indicatoreSearch';
export * from './listaDiBooleani';
export * from './listaFunzioniPrincipaliResponse';
export * from './listaFunzioniPrincipaliResponseAllOf';
export * from './listaProfiliResponse';
export * from './listaProfiliResponseAllOf';
export * from './listaUtentiRequest';
export * from './listaUtentiRequestAllOf';
export * from './listaUtentiResponse';
export * from './listaUtentiResponseAllOf';
export * from './loginRequestBean';
export * from './loginRequestBeanAllOf';
export * from './loginResponseBean';
export * from './loginResponseBeanAllOf';
export * from './loginResponseMobileBean';
export * from './loginResponseMobileBeanAllOf';
export * from './lottoContratto';
export * from './lottoContrattoAllOf';
export * from './lottoContrattoBase';
export * from './lottoContrattoBaseAllOf';
export * from './lottoContrattoBaseLookUp';
export * from './lottoContrattoBaseSearch';
export * from './lottoContrattoLookUp';
export * from './lottoContrattoSearch';
export * from './lottoUnitaControllo';
export * from './lottoUnitaControlloAllOf';
export * from './lottoUnitaControlloSearch';
export * from './mediaSegnalatoriSegn';
export * from './mediaTempiSegn';
export * from './menuItem';
export * from './modificaAccordoQuadroRequest';
export * from './modificaAccordoQuadroResponse';
export * from './modificaAllegatoServizioContrattoRequest';
export * from './modificaAllegatoServizioContrattoRequestAllOf';
export * from './modificaAllegatoServizioContrattoResponse';
export * from './modificaArticoloContrattoRequest';
export * from './modificaArticoloContrattoRequestAllOf';
export * from './modificaArticoloContrattoResponse';
export * from './modificaAssLottiPuntiRequest';
export * from './modificaAssLottiPuntiRequestAllOf';
export * from './modificaAssLottiPuntiResponse';
export * from './modificaAzioniCorrettiveRequest';
export * from './modificaAzioniCorrettiveResponse';
export * from './modificaAzioniCorrettiveResponseAllOf';
export * from './modificaBaseClienteRequest';
export * from './modificaBaseClienteResponse';
export * from './modificaClassificazioneClienteRequest';
export * from './modificaClassificazioneClienteResponse';
export * from './modificaClassificazioneClienteResponseAllOf';
export * from './modificaClassificazioneFornitoreRequest';
export * from './modificaClassificazioneFornitoreRequestAllOf';
export * from './modificaClassificazioneFornitoreResponse';
export * from './modificaClienteContrattoRequest';
export * from './modificaClienteContrattoResponse';
export * from './modificaClienteRequest';
export * from './modificaClienteResponse';
export * from './modificaContrattoRequest';
export * from './modificaContrattoResponse';
export * from './modificaCriticitaContrattoRequest';
export * from './modificaCriticitaContrattoResponse';
export * from './modificaCriticitaContrattoResponseAllOf';
export * from './modificaElementoControlloContrattoRequest';
export * from './modificaElementoControlloContrattoRequestAllOf';
export * from './modificaElementoControlloContrattoResponse';
export * from './modificaElementoControlloRequest';
export * from './modificaElementoControlloResponse';
export * from './modificaFornitoreRequest';
export * from './modificaFornitoreRequestAllOf';
export * from './modificaFornitoreResponse';
export * from './modificaGruppoUnitaOperativaRequest';
export * from './modificaGruppoUnitaOperativaResponse';
export * from './modificaIframeRequest';
export * from './modificaIframeRequestAllOf';
export * from './modificaIframeResponse';
export * from './modificaIframeVistaQLikExRequest';
export * from './modificaIframeVistaQLikExResponse';
export * from './modificaIframeVistaQLikRequest';
export * from './modificaIframeVistaQLikResponse';
export * from './modificaIndicatoreContrattoRequest';
export * from './modificaIndicatoreContrattoResponse';
export * from './modificaIndicatoreRequest';
export * from './modificaIndicatoreResponse';
export * from './modificaListaSegnalazioniRequest';
export * from './modificaListaSegnalazioniRequestAllOf';
export * from './modificaListaSegnalazioniResponse';
export * from './modificaLottoContratoBaseRequest';
export * from './modificaLottoContrattoBaseResponse';
export * from './modificaLottoContrattoBaseResponseAllOf';
export * from './modificaLottoContrattoRequest';
export * from './modificaLottoContrattoResponse';
export * from './modificaLottoContrattoResponseAllOf';
export * from './modificaLottoUnitaControlloRequest';
export * from './modificaLottoUnitaControlloResponse';
export * from './modificaNonConformitaRequest';
export * from './modificaNonConformitaResponse';
export * from './modificaPeriodoRequest';
export * from './modificaPeriodoResponse';
export * from './modificaPuntoStoccaggioRequest';
export * from './modificaPuntoStoccaggioRequestAllOf';
export * from './modificaPuntoStoccaggioResponse';
export * from './modificaSchedaDetRequest';
export * from './modificaSchedaDetResponse';
export * from './modificaSchedaTesRequest';
export * from './modificaSchedaTesResponse';
export * from './modificaSchedaTesResponseAllOf';
export * from './modificaSchedaTesRilevazioneRequest';
export * from './modificaSchedaTesRilevazioneRequestAllOf';
export * from './modificaSchedaTesRilevazioneResponse';
export * from './modificaSchedaTesRilevazioneResponseAllOf';
export * from './modificaSegnalazioneRequest';
export * from './modificaSegnalazioneResponse';
export * from './modificaSegnalazioneResponseAllOf';
export * from './modificaServizioClienteContrattoBaseRequest';
export * from './modificaServizioClienteContrattoBaseResponse';
export * from './modificaServizioClienteContrattoRequest';
export * from './modificaServizioClienteContrattoResponse';
export * from './modificaServizioClienteContrattoResponseAllOf';
export * from './modificaServizioContrattoRequest';
export * from './modificaServizioContrattoResponse';
export * from './modificaServizioRequest';
export * from './modificaServizioRequestAllOf';
export * from './modificaServizioResponse';
export * from './modificaServizioRidottoRequest';
export * from './modificaServizioRidottoRequestAllOf';
export * from './modificaServizioRidottoResponse';
export * from './modificaStatoSegnalazioneRequest';
export * from './modificaStatoSegnalazioneRequestAllOf';
export * from './modificaStatoSegnalazioneResponse';
export * from './modificaStatoSegnalazioneResponseAllOf';
export * from './modificaStoricoSegnalazioneRequest';
export * from './modificaStoricoSegnalazioneResponse';
export * from './modificaTipistoccRequest';
export * from './modificaTipistoccRequestAllOf';
export * from './modificaTipistoccResponse';
export * from './modificaUmpopcontrolloRequest';
export * from './modificaUmpopcontrolloResponse';
export * from './modificaUnitaControlloRequest';
export * from './modificaUnitaControlloRequestAllOf';
export * from './modificaUnitaControlloResponse';
export * from './modificaUnitaOperativaRequest';
export * from './modificaUnitaOperativaResponse';
export * from './modificaUtenteClienteFornitoreRequest';
export * from './modificaUtenteClienteFornitoreResponse';
export * from './modificaUtenteGruppoUORequest';
export * from './modificaUtenteGruppoUORequestAllOf';
export * from './modificaUtenteGruppoUOResponse';
export * from './modificaUtentiVisteQLikRequest';
export * from './modificaUtentiVisteQLikRequestAllOf';
export * from './modificaUtentiVisteQLikResponse';
export * from './modificaValoreClassClienteRequest';
export * from './modificaValoreClassClienteRequestAllOf';
export * from './modificaValoreClassClienteResponse';
export * from './modificaValoreClassFornitoreRequest';
export * from './modificaValoreClassFornitoreRequestAllOf';
export * from './modificaValoreClassFornitoreResponse';
export * from './modificaVisteQLikRequest';
export * from './modificaVisteQLikResponse';
export * from './nazioni';
export * from './nazioniRequestBean';
export * from './nazioniRequestBeanAllOf';
export * from './nazioniResponseBean';
export * from './nazioniResponseBeanAllOf';
export * from './nonConformita';
export * from './nonConformitaAllOf';
export * from './nonConformitaLookUp';
export * from './nonConformitaSearch';
export * from './notificaSegnalazioni';
export * from './notificaSegnalazioniAllOf';
export * from './notificaSegnalazioniLookUp';
export * from './notificaSegnalazioniSearch';
export * from './notificheSegnalazioniTipoEnum';
export * from './periodo';
export * from './periodoAllOf';
export * from './periodoLookUp';
export * from './periodoSearch';
export * from './profiloRequest';
export * from './profiloResponse';
export * from './profiloResponseAllOf';
export * from './province';
export * from './provinceRequestBean';
export * from './provinceRequestBeanAllOf';
export * from './provinceResponseBean';
export * from './provinceResponseBeanAllOf';
export * from './puntoStoccaggio';
export * from './puntoStoccaggioAllOf';
export * from './puntoStoccaggioLookUp';
export * from './puntoStoccaggioSearch';
export * from './qLikSenseJWTToken';
export * from './recaptchaResult';
export * from './regolaCalcolo';
export * from './result';
export * from './riepElemCliente';
export * from './riepElemControllo';
export * from './riepElemLottoContratto';
export * from './riepElemServizio';
export * from './schedaCompletaForDownload';
export * from './schedaCompletaForUpload';
export * from './schedaCompletaForUploadAllOf';
export * from './schedaDet';
export * from './schedaDetAllOf';
export * from './schedaDetLookUp';
export * from './schedaDetRilevazione';
export * from './schedaDetRilevazioneAllOf';
export * from './schedaDetRilevazioneSearch';
export * from './schedaDetSearch';
export * from './schedaPerBase';
export * from './schedaPerBaseSearch';
export * from './schedaPerLotto';
export * from './schedaPerLottoSearch';
export * from './schedaTes';
export * from './schedaTesAllOf';
export * from './schedaTesLookUp';
export * from './schedaTesRilevazione';
export * from './schedaTesRilevazioneAllOf';
export * from './schedaTesRilevazioneParametriGenerazione';
export * from './schedaTesRilevazioneSearch';
export * from './schedaTesSearch';
export * from './segnalazione';
export * from './segnalazioneAllOf';
export * from './segnalazioneLookUp';
export * from './segnalazioneMaxCodeSerCliCon';
export * from './segnalazioneMaxCodeSerCliConAllOf';
export * from './segnalazioneSearch';
export * from './segnalazioneStats';
export * from './segnalazioneStatsSearch';
export * from './segnalazioneWrapper';
export * from './servizio';
export * from './servizioClienteContratto';
export * from './servizioClienteContrattoAllOf';
export * from './servizioClienteContrattoBase';
export * from './servizioClienteContrattoBaseAllOf';
export * from './servizioClienteContrattoBaseLookup';
export * from './servizioClienteContrattoBaseSearch';
export * from './servizioClienteContrattoLookUp';
export * from './servizioClienteContrattoSearch';
export * from './servizioContratto';
export * from './servizioContrattoAllOf';
export * from './servizioContrattoLookUp';
export * from './servizioContrattoSearch';
export * from './servizioLookUp';
export * from './servizioRidotto';
export * from './servizioRidottoAllOf';
export * from './sincronizzazioneSearch';
export * from './sovrascriviListaArticoloContrattoRequest';
export * from './sovrascriviListaArticoloContrattoRequestAllOf';
export * from './sovrascriviListaArticoloContrattoResponse';
export * from './sovrascriviListaArticoloContrattoResponseAllOf';
export * from './sovrascriviListaCriticitaContrattoRequest';
export * from './sovrascriviListaCriticitaContrattoRequestAllOf';
export * from './sovrascriviListaCriticitaContrattoResponse';
export * from './sovrascriviListaCriticitaContrattoResponseAllOf';
export * from './sovrascriviListaElementoControlloContrattoRequest';
export * from './sovrascriviListaElementoControlloContrattoRequestAllOf';
export * from './sovrascriviListaElementoControlloContrattoResponse';
export * from './sovrascriviListaElementoControlloContrattoResponseAllOf';
export * from './sovrascriviListaIndicatoreContrattoRequest';
export * from './sovrascriviListaIndicatoreContrattoRequestAllOf';
export * from './sovrascriviListaIndicatoreContrattoResponse';
export * from './sovrascriviListaIndicatoreContrattoResponseAllOf';
export * from './ssoParams';
export * from './ssoParamsAllOf';
export * from './statContElencoContestazioni';
export * from './statContRiepilogoContestazioni';
export * from './statControlliLottoPerControllore';
export * from './statDettaglioSchede';
export * from './statDettaglioSchedeConEsposizione';
export * from './statIndServGlobalePeriodo';
export * from './statIndServGlobaleTyBasePeriodo';
export * from './statIndServGlobaleTyServizioPeriodo';
export * from './statIndServLottoPeriodo';
export * from './statIndServLottoPuntoControllo';
export * from './statNCDettaglioAzioneCorrettiva';
export * from './statNCDettaglioNonConformita';
export * from './statNCRiepilogoAzioneCorrettiva';
export * from './statNCRiepilogoNonConformita';
export * from './statNVElencoNonValutabili';
export * from './statNVRiepilogoNonValutabili';
export * from './statNonConfIndicatori';
export * from './statNonConfLottoPuntoControllo';
export * from './statSchedaDetRilS2S';
export * from './statSchedaTesRilS2S';
export * from './statiCompilazione';
export * from './statisticheContestazioniSearch';
export * from './statisticheNonConformitaSearch';
export * from './statisticheNonValutabiliSearch';
export * from './statisticheSearch';
export * from './statoValidazione';
export * from './statusCode';
export * from './statusSegnalazione';
export * from './storicoNoteSegnalazione';
export * from './storicoNoteSegnalazioneAllOf';
export * from './storicoNoteSegnalazioneSearch';
export * from './storicoSegnalazione';
export * from './storicoSegnalazioneAllOf';
export * from './storicoSegnalazioneLookUp';
export * from './storicoSegnalazioneSearch';
export * from './storiconoteSegnalazioneLookUp';
export * from './tipistocc';
export * from './tipistoccLookUp';
export * from './tipoAzioniCorrettiveEnum';
export * from './tipoNonConformitaEnum';
export * from './tipoServizio';
export * from './tracked';
export * from './trackedAllOf';
export * from './umpopcontrollo';
export * from './umpopcontrolloLookUp';
export * from './unitaControllo';
export * from './unitaControlloLookUp';
export * from './unitaControlloSearch';
export * from './unitaOperativa';
export * from './unitaOperativaAllOf';
export * from './unitaOperativeController';
export * from './unitaOperativeLookUp';
export * from './unitaOperativeSearch';
export * from './uploadAllegatoSchedaDetRilRequest';
export * from './uploadAllegatoSchedaDetRilRequestAllOf';
export * from './uploadAllegatoSchedaDetRilResponse';
export * from './uploadAllegatoSegnalazioneRequest';
export * from './uploadAllegatoSegnalazioneRequestAllOf';
export * from './uploadAllegatoSegnalazioneResponse';
export * from './uploadAllegatoSegnalazioneResponseAllOf';
export * from './uploadRequest';
export * from './uploadRequestAllOf';
export * from './uploadResponse';
export * from './uploadResponseAllOf';
export * from './uploadResult';
export * from './uploadedFile';
export * from './uploadedFileContent';
export * from './utenteClienteFornitore';
export * from './utenteClienteFornitoreAllOf';
export * from './utenteExtra';
export * from './utenteExtraAllOf';
export * from './utenteExtraLookUp';
export * from './utenteExtraSearch';
export * from './utenteGruppoUO';
export * from './utenteGruppoUOAllOf';
export * from './utenteGruppoUOLookUp';
export * from './utenteGruppoUOSearch';
export * from './utenteRequest';
export * from './utenteResponse';
export * from './utenteResponseAllOf';
export * from './utentiVisteQLik';
export * from './utentiVisteQLikAllOf';
export * from './validazioneMassivaTesRilevazioneRequest';
export * from './validazioneMassivaTesRilevazioneRequestAllOf';
export * from './valoreClassCliente';
export * from './valoreClassClienteAllOf';
export * from './valoreClassClienteLookUp';
export * from './valoreClassFornitore';
export * from './valoreClassFornitoreLookUp';
export * from './visteQLik';
export * from './visteQLikAllOf';
