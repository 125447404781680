/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ServizioClienteContratto {
    timeStamp?: string;
    dataIns?: string;
    ipInsMod?: string;
    userNameUteIns?: string;
    userNameUteMod?: string;
    idUteIns?: number;
    ipIns?: string;
    dataMod?: string;
    idUteMod?: number;
    idCon?: number;
    note?: string;
    idCli?: number;
    idSer?: number;
    Manuale?: string;
    descrizioneServizio?: string;
    id?: number;
    idCliCon?: number;
    idSerCon?: number;
    codiceServizio?: string;
    descrizioneServizioContratto?: string;
}


