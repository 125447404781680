/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatDettaglioSchedeConEsposizione { 
    lottoIdSerCon?: number;
    indicatoreId?: number;
    baseDescr?: string;
    baseId?: number;
    tipoControllo?: string;
    periodoId?: number;
    lottoDescr?: string;
    servizioId?: number;
    periododescr?: string;
    servizioPeso?: number;
    unitaControlloDescr?: string;
    lottoId?: number;
    indicatoreDescr?: string;
    peso?: number;
    servizioIdCon?: number;
    valorePesato?: number;
    idSchedaTesRil?: number;
    unitaControlloId?: number;
    valMedio?: number;
    punteggioPreventivo?: number;
    numSchedeTot?: number;
    clienteId?: number;
    elementoControlloDescr?: string;
    lottoPeso?: number;
    puntoControlloId?: number;
    clienteRagSoc?: string;
    elementoControlloId?: number;
    valMedioString?: string;
    serviziodescr?: string;
    puntoControlloDescr?: string;
    valPuntualeMedio?: number;
}


