/**
 * Escape
 * API di Escape
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificaSegnalazioni {
    timeStamp?: string;
    dataIns?: string;
    ipInsMod?: string;
    userNameUteIns?: string;
    userNameUteMod?: string;
    idUteIns?: number;
    ipIns?: string;
    dataMod?: string;
    idUteMod?: number;
    id_segnalazione?: number;
    flag_inviata?: boolean;
    flag_invio_errore?: boolean;
    descr_errore?: string;
    tipo_notifica?: string;
    data_invio?: string;
    data_ultimo_ten?: string;
    n_tentativi?: number;
    id?: number;
    corpo_notifica?: string;
    oggetto_notifica?: string;
    data_ins?: string;
}


